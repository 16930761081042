

import Vue from "vue";
import axios from "axios";

import Top from "@/components/templates/Top.vue";
import QueuesStatisticsResume from "./QueuesStatisticsResume.vue";

import i18n from "@/plugins/i18n";
import * as action from "@/store/actions";
import { mapActions, mapGetters } from "vuex";
import { tokenHeader } from "../../../services/auth";

export default Vue.extend({
  name: "QueuesStatistics",
  
  data: () => ({
    selectedQueue: "",
    dateFromModel: false,
    dateFrom: new Date().toJSON().slice(0, 10),
    hourFrom: "00:00",
    dateToModel: false,
    dateTo: new Date().toJSON().slice(0, 10),
    hourToModel: false,
    hourTo: "23:59",

    queueList: [] as any,

    results: [] as any,
    body: [] as any,

    rules: {
      required: (v: any) => !!v || i18n.t("loginView.required"),
    },
  }),

  computed: {
    ...mapGetters({
    }),
  },

  watch: {
    '$i18n.locale': {
      handler: function(newLocale, oldLocale) {
        this.updateQueueList();
      },
      immediate: true // Trigger the handler immediately on component mount
    }
  },

  components: {
    QueuesStatisticsResume,
    Top,
  },

  async mounted() {
    await this.updateQueueList();
  },

  methods: {
    ...mapActions({
      getQueueList: "crudService/getDetail",
    }),

    dateRules() {
      return this.dateFrom < this.dateTo ? true : i18n.t("rules.dateError");
    },

    async updateQueueList() {
      const actionList = await this.getQueueList({
        action: action.GET_ACTIONS,
        id: this.$store.state.auth.user.corpId,
      });

      const arr = [];

      arr.push( { text: this.$i18n.t("corpPanel.all") , value: "0" } );

      for (const key in actionList.queues) {
        arr.push( { text: key , value: actionList.queues[key] } );
      }
      
      this.queueList = arr;
    },

    async onSubmit() {
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {

        this.body = {
          from: this.dateFrom + "T" + this.hourFrom,
          to: this.dateTo + "T" + this.hourTo,
          queue: this.selectedQueue.split("_")[1]
        }
        const headers = tokenHeader();

        await axios
          .post(action.GET_LIST_STATISTICS_QUEUE, this.body, { withCredentials: true, headers })
          .then((res) => {
            this.results = res.data.sP1;
          })
          .catch(() => {
            alert("GET_LIST_STATISTICS_QUEUE is not working");
          });
      }
    },
  },
});
