
import Vue from 'vue';

import CorpsSpends from './CorpsSpends.vue';
import KPIS from "./KPI's.vue";
import ExtensionsGraph from './ExtensionsGraph.vue';
import CallPeriods from './CallPeriods.vue';

import config from '../../config.js';
import { mapActions, mapGetters } from 'vuex';
import i18n from '@/plugins/i18n';
import StatCard from '@/components/stats/StatCard.vue';
import * as action from '@/store/actions';

export default Vue.extend({
  data: () => ({
    call: i18n.t('corpPanel.all'),
    days: i18n.t('corpPanel.today'),

    durationOfCall: 'duracionSal',

    corpsList: [],
    totalExtensions: 0,
    onlineExtensions: 0,
    inCalls: 0,
    outCalls: 0,
    spend: 0,
  }),

  computed: {
    ...mapGetters({
      dashExtensions: 'panel/dashExtensions',
      hoverColor: 'lookAndFeel/hoverColor',
      menuIconColor: 'lookAndFeel/menuIconColor',
      menuFontColor: 'lookAndFeel/menuFontColor',
      exchange: 'auth/exchange',
    }),
  },

  components: {
    CorpsSpends,
    KPIS,
    ExtensionsGraph,
    CallPeriods,
    StatCard,
  },

  watch: {
    dashExtensions: {
      handler: function(newVal) {
        this.corpsList = newVal;

        this.totalExtensions = newVal.reduce(
          (acc: any, current: any) => acc + +current.totalExt,
          0
        );
        this.onlineExtensions = newVal.reduce(
          (acc: any, current: any) => acc + +current.totalOnline,
          0
        );
      },
      immediate: true, // Trigger the handler immediately on component mount
    },
  },

  methods: {
    ...mapActions({
      getCorpsList: 'crudService/getList',
      getExtenList: 'crudService/getList',
    }),
    async fetchExtenList() {
      const response = await this.getExtenList(action.ALL_EXTENSION);

      let cache = response.reduce((acc, current) => acc + +current.totalExt, 0);
      console.log(cache)
      this.totalExtensions = cache;
    },

    getData() {
      setInterval(async () => {
        let cantDays;

        switch (this.days) {
          case i18n.t('corpPanel.today'):
            cantDays = 0;
            break;

          case i18n.t('corpPanel.yesterday'):
            cantDays = 1;
            break;

          case i18n.t('corpPanel.oneWeek'):
            cantDays = 7;
            break;
        }

        let response1 = await this.getCorpsList(
          `${config.url}dash/kpi/days/${cantDays}`
        );

        this.inCalls = response1[0].totalEntrantes;
        this.outCalls = response1[0].totalSalientes;
      }, 60000);
    },

    changeTypeOfCall(type: string) {
      if (type === i18n.t('corpPanel.in')) {
        this.durationOfCall = 'duracionEnt';
      }
      if (type === i18n.t('corpPanel.out')) {
        this.durationOfCall = 'duracionSal';
      }
    },

    callDuracion() {
      return this.durationOfCall;
    },

    manageSpend(newSpend: any) {
      this.spend = newSpend;
    },
  },

  async mounted() {
    let cantDays;

    switch (this.days) {
      case i18n.t('corpPanel.today'):
        cantDays = 0;
        break;

      case i18n.t('corpPanel.yesterday'):
        cantDays = 1;
        break;

      case i18n.t('corpPanel.oneWeek'):
        cantDays = 7;
        break;
    }

    let response1 = await this.getCorpsList(
      `${config.url}dash/kpi/days/${cantDays}`
    );

    this.inCalls = response1[0].totalEntrantes;
    this.outCalls = response1[0].totalSalientes;
    this.getData();

    // Acá se está tomando la cantidad de extensiones del backend pero no debería ser así.
    this.fetchExtenList();
    setInterval(() => {
      this.fetchExtenList();
    }, 60000);
  },
});
