

import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
import axios from "axios";

import * as action from "../../../store/actions"
import { tokenHeader } from "../../../services/auth";
import i18n from "@/plugins/i18n";

export default Vue.extend({
  name: "ResumeIVRsChart",
  props: ["ivrName", "ivrId", "from", "to"],

  components: {
    apexchart: VueApexCharts
  },

  data() {
    return {
      dialog: false,
      series: [] as number[],
      chartOptions: {
        chart: {
          type: 'pie',
        },
        labels: [
          i18n.t("misc.option") + " 0", 
          i18n.t("misc.option") + " 1", 
          i18n.t("misc.option") + " 2", 
          i18n.t("misc.option") + " 3", 
          i18n.t("misc.option") + " 4", 
          i18n.t("misc.option") + " 5", 
          i18n.t("misc.option") + " 6", 
          i18n.t("misc.option") + " 7", 
          i18n.t("misc.option") + " 8", 
          i18n.t("misc.option") + " 9", 
        ],
      }
    }
  },

  methods: {
    async getData() {
      const headers = tokenHeader();

      this.series = [];

      await axios
        .post(`${action.IVR_STATISTICS_CHART}${this.ivrId}`,{from: this.from, to:this.to} ,{ withCredentials: true, headers })
        .then((res) => {

          for (const key in res.data) {
            if (Object.prototype.hasOwnProperty.call(res.data, key)) {
              this.series.push(res.data[key])
            }
          }
          
        })
        .catch(() => {
          alert("IVR_STATISTICS_CHART is not working");
        });
    }
  },

    
});

