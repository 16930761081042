
import Vue from 'vue';

import Top from '@/components/templates/Top.vue';

import { mapActions, mapGetters } from 'vuex';
import store from '@/store/index';

export default Vue.extend({
  name: 'Logmon',
  data() {
    return {
      allActions:  ["ChannelEnteredBridge", "ChannelDestroyed", "ChannelCreated", "DeviceStateChanged", "Dial", 
      "ChannelVarset", "ChannelDialplan","ChannelHangupRequest", "ChannelHold", "ChannelLeftBridge","ChannelCallerId","ChannelStateChange"],

      switchModel: ["ChannelEnteredBridge", "ChannelDestroyed", "ChannelCreated", "DeviceStateChanged", "Dial", 
      "ChannelVarset", "ChannelDialplan","ChannelHangupRequest", "ChannelHold", "ChannelLeftBridge","ChannelCallerId","ChannelStateChange"],
   
      
    };
  },

  components: {
    Top,
  },

  watch: {
    log(newVal) {
      this.pushElement(newVal);
    },
  },

  computed: {
    ...mapGetters({
      log: 'logmon/log',
    }),
  },
  methods: {
    ...mapActions({
      initLogmon: 'logmon/initLogmon',
      stopLogmon: 'logmon/stopLogmon',
      stopPanel: 'panel/stopPanel',
    }),

    pushElement(newVal: any) {
      let miDiv = document.getElementById('elLog');

      let type = newVal.type;
      let data = newVal.timestamp;
      let device = newVal?.device_state;

      miDiv!.innerHTML =
        `
        <span class="${type} logstyle">
        
        <b style="color: red;"> type : ${type} </b>
        <br />
        data : ${data}
        ${!!device &&
          `
          <br />
          device : ${device.name} ====>>>> ${device.state}
          
          `}
        <br />
        ${JSON.stringify(newVal)}
        <br />
      </span>
      ` + miDiv!.innerHTML;

      this.hideGroup();
    },

    hideGroup() {
     

        if (this.switchModel[0]!==null) {  
          var elements = document.getElementsByClassName("ChannelEnteredBridge") as HTMLCollectionOf<HTMLElement>;
          for (var i = 0; i < elements.length; i++) {
            elements[i].style.display = 'block';
          }
        } else if(this.switchModel[0]===null){
          var elements = document.getElementsByClassName("ChannelEnteredBridge") as HTMLCollectionOf<HTMLElement>;
          for (var i = 0; i < elements.length; i++) {
            elements[i].style.display = 'none';
          }
        }

        if (this.switchModel[1]!==null) {
          var elements1 = document.getElementsByClassName("ChannelDestroyed") as HTMLCollectionOf<HTMLElement>;
          for (var i = 0; i < elements1.length; i++) {
            elements1[i].style.display = 'block';
          }
        } else if(this.switchModel[1]===null){
          var elements1 = document.getElementsByClassName("ChannelDestroyed") as HTMLCollectionOf<HTMLElement>;          
            for (var i = 0; i < elements1.length; i++) {
              elements1[i].style.display = 'none';
            }
        }

        if (this.switchModel[2]!==null) {
          var elements2 = document.getElementsByClassName("ChannelCreated") as HTMLCollectionOf<HTMLElement>;
          for (var i = 0; i < elements2.length; i++) {
            elements2[i].style.display = 'block';
          }
        } else if(this.switchModel[2]===null){
          var elements2 = document.getElementsByClassName("ChannelCreated") as HTMLCollectionOf<HTMLElement>;
            for (var i = 0; i < elements2.length; i++) {
              elements2[i].style.display = 'none';
            }
        }

        if (this.switchModel[3]!==null) {
          var elements3 = document.getElementsByClassName("DeviceStateChanged") as HTMLCollectionOf<HTMLElement>;
          for (var i = 0; i < elements3.length; i++) {
            elements3[i].style.display = 'block';
          }
        } else if(this.switchModel[3]===null){
          var elements3 = document.getElementsByClassName("DeviceStateChanged") as HTMLCollectionOf<HTMLElement>;
          for (var i = 0; i < elements3.length; i++) {
            elements3[i].style.display = 'none';
          }
        }

        if (this.switchModel[4]!==null) {
          var elements4 = document.getElementsByClassName("Dial") as HTMLCollectionOf<HTMLElement>;
          for (var i = 0; i < elements4.length; i++) {
            elements4[i].style.display = 'block';
          }
        } else if(this.switchModel[4]===null){
          var elements4 = document.getElementsByClassName("Dial") as HTMLCollectionOf<HTMLElement>;
          for (var i = 0; i < elements4.length; i++) {
            elements4[i].style.display = 'none';
          }
        }

        if (this.switchModel[5]!==null) {
          var elements5 = document.getElementsByClassName("ChannelVarset") as HTMLCollectionOf<HTMLElement>;
          for (var i = 0; i < elements5.length; i++) {
            elements5[i].style.display = 'block';
          }
        } else if(this.switchModel[5]===null){
          var elements5 = document.getElementsByClassName("ChannelVarset") as HTMLCollectionOf<HTMLElement>;
          for (var i = 0; i < elements5.length; i++) {
            elements5[i].style.display = 'none';
          }
        }

        if (this.switchModel[6]!==null) {
          var elements6 = document.getElementsByClassName("ChannelDialplan") as HTMLCollectionOf<HTMLElement>;
          for (var i = 0; i < elements6.length; i++) {
            elements6[i].style.display = 'block';
          }
        } else if(this.switchModel[6]===null){
          var elements6 = document.getElementsByClassName("ChannelDialplan") as HTMLCollectionOf<HTMLElement>;
          for (var i = 0; i < elements6.length; i++) {
            elements6[i].style.display = 'none';
          }
        }

        if (this.switchModel[7]!==null) {
          var elements7 = document.getElementsByClassName("ChannelHangupRequest") as HTMLCollectionOf<HTMLElement>;
          for (var i = 0; i < elements7.length; i++) {
            elements7[i].style.display = 'block';
          }
        } else if(this.switchModel[7]===null){
          var elements7 = document.getElementsByClassName("ChannelHangupRequest") as HTMLCollectionOf<HTMLElement>;
          for (var i = 0; i < elements7.length; i++) {
            elements7[i].style.display = 'none';
          }
        }

        if (this.switchModel[8]!==null) {
          var elements8 = document.getElementsByClassName("ChannelHold") as HTMLCollectionOf<HTMLElement>;
          for (var i = 0; i < elements8.length; i++) {
            elements8[i].style.display = 'block';
          }
        } else if(this.switchModel[8]===null){
          var elements8 = document.getElementsByClassName("ChannelHold") as HTMLCollectionOf<HTMLElement>;
          for (var i = 0; i < elements8.length; i++) {
            elements8[i].style.display = 'none';
          }
        }

        if (this.switchModel[9]!==null) {
          var elements9 = document.getElementsByClassName("ChannelLeftBridge") as HTMLCollectionOf<HTMLElement>;
          for (var i = 0; i < elements9.length; i++) {
            elements9[i].style.display = 'block';
          }
        } else if(this.switchModel[9]===null){
          var elements9 = document.getElementsByClassName("ChannelLeftBridge") as HTMLCollectionOf<HTMLElement>;
          for (var i = 0; i < elements9.length; i++) {
            elements9[i].style.display = 'none';
          }
        }

        
        if (this.switchModel[10]!==null) {
          var elements10 = document.getElementsByClassName("ChannelCallerId") as HTMLCollectionOf<HTMLElement>;
          for (var i = 0; i < elements10.length; i++) {
            elements10[i].style.display = 'block';
          }
        } else if(this.switchModel[10]===null){
          var elements10 = document.getElementsByClassName("ChannelCallerId") as HTMLCollectionOf<HTMLElement>;
          for (var i = 0; i < elements10.length; i++) {
            elements10[i].style.display = 'none';
          }
        }

        if (this.switchModel[11]!==null) {
          var elements11 = document.getElementsByClassName("ChannelStateChange") as HTMLCollectionOf<HTMLElement>;
          for (var i = 0; i < elements11.length; i++) {
            elements11[i].style.display = 'block';
          }
        } else if(this.switchModel[11]===null){
          var elements11 = document.getElementsByClassName("ChannelStateChange") as HTMLCollectionOf<HTMLElement>;
          for (var i = 0; i < elements11.length; i++) {
            elements11[i].style.display = 'none';
          }
        }



         


         
        


         


    },
  },

  mounted() {
    store
      .dispatch('lookAndFeel/getLookandfeel')
      .then(() => {
        this.stopPanel();
      })
      .then(() => {
        this.initLogmon();
      });
  },
});
