
import Vue from 'vue';
import axios from 'axios';
import { mapGetters } from 'vuex';
import moment from 'moment';
interface Room {
  id: number;
  name: string;
  src: string;
  fromDate: string;
  toDate: string;
}

export default Vue.extend({
  data() {
    return {
      menu: false,
      selectedRoom: null,
      todaysMeets: [] as Room[],
      date: new Date(),
      rooms: [] as Room[],
      generalMeets: [] as Room[],
    };
  },
  computed: {
    ...mapGetters({
      corp: 'auth/corp',
      user: 'auth/user',
      softwareId: 'auth/softwareId',
      menuFontColor: 'lookAndFeel/menuFontColor',
    }),
  },
  async mounted() {
    await this.refreshListDailyMeet();
    await this.refreshRoom();
    await this.refreshListGeneralMeet();
  },
  methods: {
    
    async refreshListDailyMeet() {
      try {
        const response = await axios.post('http://177.11.49.135:4000/DateRooms/rooms-by-date', {
          corpId: this.corp.id,
          softwareId: this.softwareId,
          userId: this.user.nick,
          date: this.toUTC(this.date),
        });
        if (response.data && response.data.length > 0) {
          this.todaysMeets = response.data.map((room: { id: number; name: string; src: string; fromDate: string; toDate: string }) => ({
            id: room.id,
            name: room.name,
            src: room.src,
            fromDate: room.fromDate,
            toDate: room.toDate,
          }));
        } else {
          this.todaysMeets = [];
        }
      } catch (error) {
        console.error('Error fetching room list:', error);
        this.rooms = [];
      }
    },
    async refreshRoom() {
      const response = await axios.get('http://177.11.49.135:4000/rooms/', {
        params: {
          corpId: this.corp.id,
          softwareId: this.softwareId
        }
      });
      if (response.data.rooms && response.data.rooms.length > 0) {
        this.rooms = response.data.rooms.map((room: { name: string; link: string }) => ({
          name: room.name,
          src: room.link,
        }));
      }
    },
    async refreshListGeneralMeet() {
      try {
        const response = await axios.post('http://177.11.49.135:4000/DateRooms/rooms-from-date', {
          corpId: this.corp.id,
          softwareId: this.softwareId,
          userId: this.user.nick,
        });

        if (response.data && response.data.length > 0) {
          // Mapear los datos
          const meets = response.data.map((room: { id: number; name: string; src: string; fromDate: string; toDate: string }) => ({
            id: room.id,
            name: room.name,
            src: room.src,
            fromDate: room.fromDate,
            toDate: room.toDate,
          }));

          // Ordenar los datos por el campo `fromDate`
          this.generalMeets = meets.sort((a, b) => {
            const dateA = new Date(a.fromDate).getTime();
            const dateB = new Date(b.fromDate).getTime();
            return dateA - dateB;
          });
        } else {
          this.generalMeets = [];
        }
      } catch (error) {
        console.error('Error fetching room list:', error);
        this.generalMeets = [];
      }
    },
    toUTC(dateString: string) {
      const localDate = moment(dateString);
      const utcDate = moment.utc({
        year: localDate.year(),
        month: localDate.month(),
        day: localDate.date(),
        hour: localDate.hour(),
        minute: localDate.minute(),
        second: localDate.second()
      });
      return utcDate.format(); // Return the formatted UTC date string
    },
    selectRoom(room) {
      this.selectedRoom = room;
      this.menu = false; // Close the menu after selection
      this.onRoomChange(room); // Call the onRoomChange method
    },
    onRoomChange(room) {
      const baseUrl = 'https://meet.voipgroup.com';
      const nombre = room.name || room.nombre
      const src = `/${this.corp.id}/${this.softwareId}/${nombre}`
      const fullUrl = `${baseUrl}${src}`;
      window.open(fullUrl, '_blank');
    },
    formatDate(dateString: string) {
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    formatTime(dateString: string) {
      return moment.utc(dateString).format('h:mm A'); // Esto mostrará la hora tal cual viene en el string
    },
    isLessThan30Minutes(fromDate: string) {
      const nowUTC = moment.utc();
      const endDateUTC = moment.utc(fromDate);
      const diff = endDateUTC.diff(nowUTC, 'minutes');
      return diff < -145;
    },
    onMenuInput(val: boolean) {
      if (val) {
        // Menu is open
        this.refreshListDailyMeet();
        this.refreshRoom();
        this.refreshListGeneralMeet();
      }
    }
  },
});
