
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import Panel from '@/plugins/panelHub';

import ThemeSwitcher from '@/components/templates/ThemeSwitcher.vue';
import Options from '@/components/templates/Options.vue';

import { eraseCookie } from '@/plugins/utils/cookieManager';
import * as action from '@/store/actions';

import Rooms from './Rooms.vue';
export const mainBus = new Vue();

export default Vue.extend({
  data: () => ({
    fav: true,
    menu: false,
    message: false,
    hints: true,
    darkMode: false,
    menuIconColorLocal: '',
    menuFontColorLocal: '',
    hoverColorLocal: '',

    backgroundColorLocal: '',

    currentPath: "Hola Mundo"
  }),

  methods: {
    ...mapActions({
      signOut: 'auth/signOut',
      getCorpList: 'crudService/getList',
    }),
    toggleDarkMode: function() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.darkMode = !this.darkMode;
    },

    navigateTo(navTo: string) {
      if (navTo != this.$route.name) {
        this.$router.replace({
          name: navTo,
          params: {
            corp: localStorage.getItem('corp') || '',
            lang: this.$i18n.locale,
          },
        });
      }
    },
    async handleSignOut() {
      await Panel.stop();

      await this.signOut();
      eraseCookie('selectedCorpId');
    },

    getCurrentPath() {
      let path = window.location.pathname;
      let pathArray = path.split("/")
      
      if (pathArray.length > 5) {
        this.currentPath = pathArray[pathArray.length-3] + "." + pathArray[pathArray.length-2]
      } else {
        this.currentPath = pathArray[pathArray.length-2] + "." + pathArray[pathArray.length-1]
      }

      if(this.currentPath == ".") this.currentPath = "Ramais"
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      softwareId: 'auth/softwareId',
      room: 'auth/rooms',
      hoverColor: 'lookAndFeel/hoverColor',
      menuIconColor: 'lookAndFeel/menuIconColor',
      menuFontColor: 'lookAndFeel/menuFontColor',
      menuColor: 'lookAndFeel/menuColor',
      backgroundColor: 'lookAndFeel/backgroundColor',
    }),
  },

  components: {
    ThemeSwitcher,
    Options,
    Rooms,
  },

  created() {
    this.getCurrentPath()
    
    mainBus.$on(action.RELOAD_LOOK_AND_FEEL, () => {
      this.hoverColorLocal = this.hoverColor;
      this.menuIconColorLocal = this.menuIconColor;
      this.menuFontColorLocal = this.menuFontColor;
    });
  },

  // Reestructuración para rutear este componente. Ver documentación en App.vue
  updated() {
    this.getCurrentPath()
  }
});
