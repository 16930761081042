
import Vue from "vue";

import Top from "@/components/templates/Top.vue";

import msgCodes from "@/extendedFunctions/msgCodes";
import { mapActions } from "vuex";
import i18n from "@/plugins/i18n";
import config from "../../../config.js";
import * as action from "@/store/actions";
import { Dialer, Queue, Trunk } from "@/types/conf";

export default Vue.extend({
  name: "DialerDetail",
  props: ["dialerToUpdate", "isToEdit", "isToNew"],
  components: {
    Top,
  },
  data: () => ({
    dialog: false,

    alertType: "",
    alertMessage: "",
    valid: true,
    createWebUser: false,
    showPass: false,

    menu: false,
    menu2: false,
    time: null as any,
    model: 1,

    Days: [
      { day: 0, text: i18n.t("misc.monday") },
      { day: 1, text: i18n.t("misc.tuesday") },
      { day: 2, text: i18n.t("misc.wednesday") },
      { day: 3, text: i18n.t("misc.thursday") },
      { day: 4, text: i18n.t("misc.friday") },
      { day: 5, text: i18n.t("misc.saturday") },
      { day: 6, text: i18n.t("misc.sunday") },
    ],

    dialer: {
      name: "",
      comments: "",
      action: "msg",
      trunkId: null,
      callLimit: 0,
      timeout: 30,
      sound: null,
      retries: 0,
      days: [],
      horaDesde: null,
      horaHasta: null,
      soundReq: "sound",
      csvFile: null,
    } as Dialer,
    actionList: [
      {
        text: i18n.t("misc.message"),
        value: "msg",
      },
      {
        text: i18n.t("misc.queue"),
        value: "queue",
      },
    ],
    trunkList: [] as Trunk[],
    queueList: [] as Queue[],
    playingSound: [] as HTMLAudioElement[],
    stateIcons: [] as string[],
    iconsetting: true,
    rules: {
      required: (v: any) => !!v || i18n.t("loginView.required"),
    },
    isNew: false,
  }),
  methods: {
    ...mapActions({
      getTrunkList: "crudService/getList",
      newDialer: "crudService/newObject",
      updateDialer: "crudService/updateObject",
    }),
    descriptionRules(t: string) {
      return t.match(/^[a-zA-Z0-9_ ]*$/) ? true : i18n.t("rules.alphanum");
    },

    greaterThanOrEqualTo(n: number) {
      return n >= 0 ? true : i18n.t("rules.notZero");
    },

    fromToRules(from: string | number | null, to: string | number | null) {
      let desde = new Date("01/01/2000 " + from);
      let hasta = new Date("01/01/2000 " + to);
      return desde < hasta ? true : i18n.t("rules.secondHour");
    },

    dialerDetailHandler(dialer: any) {
      this.dialer.name = dialer.name;
      this.dialer.comments = dialer.comments;
      this.dialer.action = dialer.action;
      this.dialer.trunkId = dialer.trunkId;
      this.dialer.callLimit = dialer.callLimit;
      this.dialer.timeout = dialer.timeout;
      this.dialer.sound = dialer.sound;
      this.dialer.retries = dialer.retries;

      dialer.days.split(",").forEach((element: string, index: number) => {
        this.dialer.days[index] = parseInt(element);
      });

      let horaDesde = dialer.horaDesde
        ? dialer.horaDesde.toString().split("")
        : null;
      horaDesde =
        horaDesde.length > 3
          ? horaDesde[0] + horaDesde[1] + ":" + horaDesde[2] + horaDesde[3]
          : horaDesde[0] + ":" + horaDesde[1] + horaDesde[2];
      this.dialer.horaDesde = horaDesde;

      let horaHasta = dialer.horaHasta
        ? dialer.horaHasta.toString().split("")
        : null;
      horaHasta =
        horaHasta.length > 3
          ? horaHasta[0] + horaHasta[1] + ":" + horaHasta[2] + horaHasta[3]
          : horaHasta[0] + ":" + horaHasta[1] + horaHasta[2];
      this.dialer.horaHasta = horaHasta;

      this.dialer.csvFile = dialer.csvFile;
    },

    timeToInt(t: string) {
      const arr = t.split(":");
      const n = arr[0] + arr[1];
      return parseInt(n);
    },
    async validate() {
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        let desde = !!this.dialer.horaDesde
          ? this.timeToInt(this.dialer.horaDesde as string)
          : 0;
        let hasta = !!this.dialer.horaHasta
          ? this.timeToInt(this.dialer.horaHasta as string)
          : 0;

        let dialer = new FormData();
        dialer.append("name", this.dialer.name);
        dialer.append("comments", this.dialer.comments);
        dialer.append("action", this.dialer.action);
        dialer.append("sound", this.dialer.soundReq);
        dialer.append("sound1", this.dialer.sound);
        dialer.append("days", this.dialer.days.toString());
        dialer.append("horaDesde", desde.toString());
        dialer.append("horaHasta", hasta.toString());
        dialer.append("csv", this.dialer.csvFile);
        !!this.dialer.trunkId
          ? dialer.append("trunkId", this.dialer.trunkId?.toString())
          : null;
        !!this.dialer.callLimit
          ? dialer.append("callLimit", this.dialer.callLimit.toString())
          : null;
        !!this.dialer.timeout
          ? dialer.append("timeout", this.dialer.timeout.toString())
          : null;
        !!this.dialer.retries
          ? dialer.append("retries", this.dialer.retries.toString())
          : null;

        let response;
        if (this.isNew) {
          response = await this.newDialer({
            action: action.DIALER_CONTROLLER,
            obj: dialer,
          });
        } else {
          dialer.append("id", this.dialerToUpdate.id);
          response = await this.updateDialer({
            action: action.DIALER_CONTROLLER,
            obj: dialer,
          });
        }

        // This set if the form must persist or don't
        this.dialog = response >= 400

        this.alertMessage = msgCodes(response, this.isNew).alertMessage;
        this.alertType = msgCodes(response, this.isNew).alertType;

        this.$store.dispatch("snackbar/showSnack", {
          message: this.alertMessage,
          color: this.alertType,
          timeout: 3000,
        });
      }

      this.$emit("refreshList", this.dialog)
    },
    
    setPlay(item: Dialer) {
      this.iconsetting = !this.iconsetting;
      let icon = this.stateIcons[0];

      if (icon === "mdi-play") {
        icon = "mdi-pause";
        this.playSound(item);
      } else {
        icon = "mdi-play";
        this.pauseSound();
      }

      this.stateIcons[0] = icon;
      this.iconsetting = !this.iconsetting;
    },
    async playSound(val: Dialer) {
      const file = val.sound;
      const url = config.url + "dialer/getsound/" + file;
      const token = localStorage.getItem("token");
      const result = await fetch(url, {
        credentials: "same-origin",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const blob = await result.blob();
      if (blob) {
        const url = URL.createObjectURL(blob);
        this.playingSound.push(new Audio(url));
        this.playingSound[0].addEventListener("ended", () => {
          this.stateIcons[0] = "mdi-play";
        });
        this.playingSound[0].play();
      } else {
        alert("Cannot load");
      }
    },
    pauseSound() {
      this.playingSound[0].pause();
    },
    resetInput() {
      this.dialer.sound = this.dialerToUpdate.sound;
    },
  },
  async mounted() {
    const isNew =
      this.dialerToUpdate == null || this.dialerToUpdate == "undefined";

    this.isNew = isNew;
    if (!isNew) {
      this.dialerDetailHandler(this.dialerToUpdate);
    }
    this.trunkList = await this.getTrunkList(action.TRUNK_CONTROLLER);

    this.stateIcons.push("mdi-play");
  },
});
