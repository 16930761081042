

import Vue from "vue";

import msgCodes from "@/extendedFunctions/msgCodes";
import { mapActions, mapGetters } from "vuex";
import i18n from "@/plugins/i18n";
import * as action from "@/store/actions";
import { Follow } from "@/types/conf";
import store from "@/store/index";

export default Vue.extend({
  name: "",
  props: ["followToUpdate", "exten", "isToEdit", "isToNew"],
  data: () => ({
    dialog: false,

    alertType: "",
    alertMessage: "",
    valid: false,

    showPass: false,

    newFollow: {
      number: null,
      order: null,
      timeout: null,
    } as Follow,

    rules: {
      required: (v: any) => !!v || i18n.t("loginView.required"),
    },
    isNew: false,
  }),

  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  methods: {
    ...mapActions({
      createFollow: "crudService/newObject",
      updateFollow: "crudService/updateObject",
    }),
    progress(pass: string) {
      return Math.min(100, pass.length * 10);
    },
    orderRules(n: number) {
      return n >= 0 && n <= 10 ? true : i18n.t("rules.between0and10");
    },
    timeoutRules(n: number) {
      return n >= 0 && n <= 99 ? true : i18n.t("rules.between0and99");
    },

    followDetailHandler(follow: Follow) {
      this.newFollow.number = follow.number;
      this.newFollow.order = follow.order;
      this.newFollow.timeout = follow.timeout;
    },
    async validate() {
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        let newFollow = {
          id: null as any,
          Number: this.newFollow.number,
          Order: parseInt(this.newFollow.order as unknown as string),
          Timeout: parseInt(this.newFollow.timeout as unknown as string),
          TypeCall: "string",
          Type: 1,
          EntityId: this.exten.id,
        };

        let response;
        if (this.isNew) {
          response = await this.createFollow({
            action: action.FOLLOW_CONTROLLER,
            obj: newFollow,
          });
        } else {
          newFollow.id = this.followToUpdate.id;
          response = await this.updateFollow({
            action: action.FOLLOW_CONTROLLER,
            obj: newFollow,
          });
        }

        // This set if the form must persist or don't
        this.dialog = response >= 400;

        this.alertMessage = msgCodes(response, this.isNew).alertMessage;
        this.alertType = msgCodes(response, this.isNew).alertType;

        this.$store.dispatch("snackbar/showSnack", {
          message: this.alertMessage,
          color: this.alertType,
          timeout: 3000,
        });

        this.$emit("refreshList", this.dialog)
      }
    },
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch("lookAndFeel/setCorpName");
    const corpName = store.state.lookAndFeel.corpName as string;
    if (!!to.params.exten) {
      next();
    } else {
      next({
        name: "extension",
        params: { corp: corpName, lang: navigator.language.slice(0, 2) },
      });
    }
  },
  async mounted() {
    const isNew =
      this.followToUpdate == null || this.followToUpdate == "undefined";

    this.isNew = isNew;

    if (!isNew) {
      this.followDetailHandler(this.followToUpdate);
    }
  },
});
