
import { mapActions, mapGetters } from "vuex";

import i18n from "@/plugins/i18n";

import CSV from "@/components/templates/CSV.vue";
import CopyButton from "@/components/templates/CopyButton.vue";
import Top from "@/components/templates/Top.vue";

import * as views from "@/plugins/utils/View";
import * as crud from "@/plugins/utils/Crud";
import * as action from "@/store/actions";
import Vue from "vue";
import { Time, View } from "@/types/conf";
import TimeDetail from "./TimeDetail.vue";
import msgCodes from "@/extendedFunctions/msgCodes";

export default Vue.extend({
  name: "Time",
  data: () => ({
    canDelete: false,
    canCreate: false,
    canUpdate: false,

    alertType: "",
    alertMessage: "",
    timeList: [] as Time[],
    selectedItem: null as Time | null,
    search: "",
    dialog: false,
    dialogDelete: false,
    loadingList: false,
  }),
  computed: {
    ...mapGetters({
      views: "auth/views",
      hoverColor: "lookAndFeel/hoverColor",
      menuIconColor: "lookAndFeel/menuIconColor",
      menuFontColor: "lookAndFeel/menuFontColor",
    }),
  },
  async mounted() {
    this.loadingList = true;
    this.timeList = await this.getAllTime(action.TIME_MAIN_CONTROLLER);

    this.actionHandler();
    this.loadingList = false;
  },

  components: {
    CSV,
    CopyButton,
    Top,
    TimeDetail
},

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    ...mapActions({
      getAllTime: "crudService/getList",
      deleteTimemain: "crudService/deleteObject",
    }),
    headers() {
      return [
        {
          text: i18n.t("misc.actions"),
          value: "actions",
          sortable: false,
          align: "left",
        },
        {
          text: i18n.t("misc.name"),
          align: "start",
          value: "name",
        },
      ];
    },
    actionHandler() {
      const view = this.views.find((v: View) => v.name == views.TIME); //view name
      if (view) {
        this.canDelete = view.actions.includes(crud.DELETE);
        this.canCreate = view.actions.includes(crud.CREATE);
        this.canUpdate = view.actions.includes(crud.UPDATE);
      }
    },

    async deleteItem(item: Time) {
      this.selectedItem = item;
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      const response = await this.deleteTimemain({
        action: action.TIME_MAIN_CONTROLLER,
        id: this.selectedItem!.id
      });
      
      // This set if the form must persist or don't
      this.dialogDelete = response >= 400;

      this.alertMessage = msgCodes(response, false).alertMessage;
      this.alertType = msgCodes(response, false).alertType;

      this.$store.dispatch('snackbar/showSnack', {
        message: this.alertMessage,
        color: this.alertType,
        timeout: 3000,
      });

      this.refreshList();
    },

    closeDelete() {
      this.dialogDelete = false;
    },

    async refreshList() {
      this.loadingList = true;
      this.timeList = await this.getAllTime(action.TIME_MAIN_CONTROLLER);

      this.actionHandler();
      this.loadingList = false;
    },
  },
});
