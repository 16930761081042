
import Vue from 'vue';
import VueApexCharts from 'vue-apexcharts';
import axios from 'axios';

import * as action from '../../../store/actions';
import { tokenHeader } from '../../../services/auth';
import i18n from '@/plugins/i18n';

export default Vue.extend({
  name: 'ExtensionUsed',

  components: {
    apexchart: VueApexCharts,
  },

  data: () => ({
    series: [0],
    labels: [''] as string[],
  }),

  computed: {
    chartOptions() {
      return {
        chart: {
          type: 'radialBar',
          offsetY: -20,
          sparkline: {
            enabled: true,
          },
        },
        title: {
          text: i18n.t('misc.extenInUse'),
          align: 'center',
          margin: 10,
          offsetX: 0,
          offsetY: 15,
          floating: false,
          style: {
            fontSize: '12px',
            fontWeight: 'normal',
            fontFamily: 'Open Sans',
            color: !this.$vuetify.theme.dark ? '#263238' : '#fff',
          },
        },
        colors: ['#00c853'],
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: '#e7e7e7',
              strokeWidth: '97%',
              margin: 5, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                color: '#999',
                opacity: 1,
                blur: 2,
              },
            },
            dataLabels: {
              name: {
                show: true,
              },
              value: {
                offsetY: -45,
                fontSize: '22px',
              },
            },
          },
        },
        grid: {
          padding: {
            top: -10,
          },
        },
        labels: this.labels,
        theme: {
          mode: !this.$vuetify.theme.dark ? 'light' : 'dark',
          palette: 'palette1',
        },
      };
    },
  },

  async mounted() {
    const headers = tokenHeader();

    await axios
      .get(action.GET_EXTEN_IN_USE, { withCredentials: true, headers })
      .then((res: any) => {
        this.labels = [`${res.data.use} / ${res.data.total}`];
        this.series = [Math.floor((res.data.use / res.data.total) * 100)];
      })
      .catch(() => {
        alert('GET_EXTEN_IN_USE is not working');
      });
  },
});
