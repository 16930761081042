
import { mapGetters } from "vuex";
import Vue from "vue";
export default Vue.extend({
  props: {
    title: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    value: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters({
      hoverColor: "lookAndFeel/hoverColor",
      menuIconColor: "lookAndFeel/menuIconColor",
      menuFontColor: "lookAndFeel/menuFontColor",
    }),
  },
});
