

import Vue from "vue";
import * as action from "@/store/actions";
import { mapGetters } from "vuex";
import axios from "axios";

export default Vue.extend({
  methods: {
    async switchLocale(item: string) {

      let headers = {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Credentials': 'true',
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      };

      await axios.post(action.SET_USER_LANG, { lang: item }, { withCredentials: true, headers })
        .then(() => {
          this.$root.$i18n.locale = item;
        })

      if (this.$root.$i18n.locale !== item) {
        
        this.$root.$i18n.locale = item;
        localStorage.setItem("locale", item);
        
        if (this.$route.name !== "login" && this.$route.fullPath !== "/") {
          this.$router.replace({
            name: this.$route.path.split("/")[4],
            params: { corp: this.$route.path.split("/")[1], lang: item },
          });
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      menuIconColor: "lookAndFeel/menuIconColor",
    }),
  },
});
