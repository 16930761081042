
import Vue from 'vue';

import Top from '@/components/templates/Top.vue';

import msgCodes from '@/extendedFunctions/msgCodes';
import { mapActions, mapGetters } from 'vuex';
import i18n from '@/plugins/i18n';
import * as action from '@/store/actions';
import { Extension, Trunk, User } from '@/types/conf';
import UserDetail from '../users/UserDetail.vue';

export default Vue.extend({
  name: '',
  props: ['extenToUpdate', 'isToEdit', 'isToNew'],
  data: () => ({
    dialog: false,

    alertType: '',
    alertMessage: '',
    valid: false,
    dialogNew: false,

    createWebUser: false,
    showPass: false,

    newExten: {
      number: null,
      pass: '',
      outer: '',
      enabled: true,
      action: 'ring',
      onbusy: '',
      description: '',
      spy: false,
      callLimit: 0,
      portType: 'UDP',
      vmpass: '',
      maxContacts: 1,
      callgroup: null,
    } as Extension,
    maxContacts: 0,
    extenLen: 0,
    userList: [] as User[],

    trunkList: [] as Trunk[],

    rules: {
      required: (v: any) => !!v || i18n.t('loginView.required'),
    },
    isNew: false,
  }),
  components: {
    Top,
    UserDetail
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      menuIconColor: 'lookAndFeel/menuIconColor',
    }),
    color(): string {
      return ['error', 'warning', 'success'][Math.floor(this.progress() / 40)];
    },
    colorVm(): string {
      return ['error', 'warning', 'success'][
        Math.floor(this.progressVm() / 40)
      ];
    },
    computedTrunkList(): Trunk[] {
      return this.trunkList.filter(
        (trunk: { corpId: any }) => trunk.corpId === this.user.corpId,
        (trunknone: { corpId: null }) => trunknone.corpId == null
      );
    },
    compPorttype(): { label: string; value: string }[] {
      return [
        {
          label: 'UDP',
          value: 'UDP',
        },
        {
          label: 'WSS (WEB RTC)',
          value: 'WSS',
        },
        // {
        //   label: 'TCP',
        //   value: 'TCP',
        // },
      ];
    },
    comptUsers(): any[] {
      if (this.user.level !== 1) {
        if (this.user.level === 3) {
          return [{ id: 0, name: 'Web User' }, ...this.userList];
        }
        return this.userList.filter(
          (user: { corpId: any }) => user.corpId === this.user.corpId
        );
      } else {
        return this.userList;
      }
    },
    actionList(): { text: string; value: string }[] {
      return [
        { text: this.$i18n.t('actions.touch') as string, value: 'ring' },
        { text: this.$i18n.t('actions.followMe') as string, value: 'followMe' },
        {
          text: this.$i18n.t('actions.voiceMail') as string,
          value: 'voicemail',
        },
        { text: this.$i18n.t('actions.simTouch') as string, value: 'simTouch' },
        { text: this.$i18n.t('actions.exTouch') as string, value: 'exTouch' },
      ];
    },
    occupiedList(): { text: string; value: string }[] {
      return [
        { text: this.$i18n.t('actions.followMe') as string, value: 'followMe' },
        {
          text: this.$i18n.t('actions.voiceMail') as string,
          value: 'voicemail',
        },
        { text: this.$i18n.t('actions.exTouch') as string, value: 'exTouch' },
      ];
    },
  },
  methods: {
    ...mapActions({
      getUserList: 'crudService/getList',
      getCorpDetail: 'crudService/getDetail',
      getTrunkList: 'crudService/getList',
      newExtension: 'crudService/newObject',
      updateExtension: 'crudService/updateObject',
    }),
    progress(): number {
      return Math.min(100, this.newExten.pass.length * 10);
    },
    progressVm(): number {
      return Math.min(100, this.newExten.vmpass.length * 25);
    },

    async onCreatedUser() {
      this.dialogNew = false;
      this.userList = await this.getUserList(action.USER_CONTROLLER);
      this.newExten.user = this.userList[-1].id;
    },
    callGroupRules(n: number) {
      return (n >= 0 && n < 100) || n == null
        ? true
        : i18n.t('rules.between0and99');
    },
    callLimitRules(n: number) {
      return (n >= 100 && n < 100000) || n == null
        ? true
        : i18n.t('rules.between100and99999');
    },
    passwordRules(pass: string) {
      return pass.length > 3 ? true : i18n.t('loginView.msgPass');
    },

    passwordRegexRules(pass: string) {
      return pass.match(/^[a-zA-Z0-9_+*.@ -]*$/)
        ? true
        : i18n.t('rules.alphanumAndSpecial');
    },
    passwordVoiceMailRegexRules(pass: string) {
      return pass.match(/^[0-9]{3,4}$/) ? true : i18n.t('rules.passVoice');
    },
    descriptionRules(t: string) {
      return t.match(/^[a-zA-Z0-9 ]*$/) || t == null
        ? true
        : i18n.t('rules.alphanum');
    },
    outerRules(n: string) {
      return n.match(/^[0-9]*$/) && n !== '0' ? true : i18n.t('rules.notZero');
    },
    vmPassRules(t: string) {
      return t.length > 0 && t.length < 5 && t.match(/\d+/)
        ? true
        : i18n.t('loginView.voicePass');
    },
    maxContRules(m: number) {
      return m >= 1 && m <= this.maxContacts ? true : `1 - ${this.maxContacts}`;
    },
    webUserRules(n: null | number) {
      return n !== 0 || n == null ? true : i18n.t('loginView.required');
    },
    extenDetailHandler(exten: any) {
      this.newExten.number = exten.number;
      this.newExten.pass = exten.pass;
      this.newExten.user = exten.userId;
      this.newExten.trunkId = exten.trunkId;
      this.newExten.outer = exten.outer;
      this.newExten.enabled = exten.enabled == i18n.t("misc.yes") ? true : false;
      this.newExten.action = exten.action;
      this.newExten.onbusy = exten.onbusy;
      this.newExten.description = exten.description;
      this.newExten.spy = exten.spy == i18n.t("misc.yes") ? true : false;
      this.newExten.callLimit = exten.callLimit;
      this.newExten.portType = exten.portType;
      this.newExten.vmpass = exten.vmpass;
      this.newExten.maxContacts = exten.maxContacts;
      this.newExten.callgroup = exten.callgroup > 0 ? exten.callgroup : null ;
    },

    async validate() {
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        let newExten = {
          id: null as any,
          Number: this.newExten.number,
          Pass: this.newExten.pass,
          UserId: parseInt(this.newExten.user),
          TrunkId: this.newExten.trunkId,
          Outer: this.newExten.outer,
          Enabled: this.newExten.enabled ? 1 : 0,
          Action: this.newExten.action,
          Onbusy: this.newExten.onbusy,
          Description: this.newExten.description,
          Spy: this.newExten.spy ? 1 : 0,
          CallLimit: 1,
          //callLimit: parseInt(this.newExten.callLimit),
          PortType: this.newExten.portType,
          Vmpass: this.newExten.vmpass,
          MaxContacts: this.newExten.maxContacts,
          Callgroup: !!this.newExten.callgroup ? this.newExten.callgroup : 0,
        };

        let response;
        if (this.isNew) {
          response = await this.newExtension({
            action: action.EXTENSION_CONTROLLER,
            obj: newExten,
          });
        } else {
          newExten.id = this.extenToUpdate.id;
          response = await this.updateExtension({
            action: action.EXTENSION_CONTROLLER,
            obj: newExten,
          });
        }
        console.log("asdasdasdasd");
        console.log(response);
        // This set if the form must persist or don't
        this.dialog = response >= 400;

        this.alertMessage = msgCodes(response, this.isNew).alertMessage;
        this.alertType = msgCodes(response, this.isNew).alertType;

        this.$store.dispatch('snackbar/showSnack', {
          message: this.alertMessage,
          color: this.alertType,
          timeout: 3000,
        });
      }

      this.$emit('refreshList', this.dialog);
    },
    numRules(n: number) {
      switch (this.extenLen) {
        case 3:
          return n.toString().match(/^(?!199$)((?:[2-9]\d\d)|999)$/)
            ? true
            : i18n.t('rules.between200and999');
        case 4:
          return n.toString().match(/^(?!999$)((?:[1-9]\d\d\d)|9999)$/)
            ? true
            : i18n.t('rules.between1000and9999');
        default:
          return false;
      }
    },
  },

  async mounted() {
    const isNew =
      this.extenToUpdate == null || this.extenToUpdate == 'undefined';

    this.isNew = isNew;
    const corp = await this.getCorpDetail({
      action: action.CORP_CONTROLLER,
      id: this.$store.state.auth.user.corpId,
    });
    this.extenLen = corp.extLen;
    this.maxContacts = corp.maxContacts;

    if (!isNew) {
      this.extenDetailHandler(this.extenToUpdate);
    }
    this.userList = await this.getUserList(action.USER_CONTROLLER);

    this.trunkList = await this.getTrunkList(action.TRUNK_CONTROLLER);
  },
});
