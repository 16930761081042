
import { mapGetters } from "vuex";
import Vue from "vue";

export default Vue.extend({
  props: ["view"],
  computed: {
    ...mapGetters({
      menuIconColor: "lookAndFeel/menuIconColor",
    }),
  },
});
