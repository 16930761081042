
import Vue from 'vue';
import axios from 'axios';
import Top from '@/components/templates/Top.vue';
import { mapGetters } from 'vuex';
import moment from 'moment-timezone';
import CalendarDetail from './CalendarDetail.vue'

import VueClipboard from 'vue-clipboard2'

interface Meet {
  id: number;
  name: string;
  date: string;
  start: string;
  end: string;
  src: string;
  userOption: string;
  selectedUsers: string[];
  creator: string;
}
export default Vue.extend({
  name: 'Calendar',
  components: {
    Top,
    CalendarDetail
  },
  data() {
    return {
      intervalMinutes: 30,
      intervalMinuteOptions: [15, 30, 60],
      selectedDate: new Date(),
      newEventDialog: false,
      selectedEvent: {} as Meet,
      newEvent: {} as Meet,
      dialog: false,
      isToNew: true, // Asume nueva reunión por defecto
      selected: false,
      users: [],
      option: null,
      events: [] as Meet[],
      confirmDeleteDialog: false,
      eventToDelete: null,
      copyDialog: false, // Nueva propiedad para el diálogo de copia
      copyText: '', // Texto a copiar
    };
  },
  watch: {
    '$route.params.date'(newDate) {
      this.setSelectedDate(newDate);
    }
  },
  created() {
    const dateParam = this.$route.params.date;
    this.calendarEvents = this.$route.params.events || this.calendarEvents;
    this.setSelectedDate(dateParam);
  },
  computed: {
    ...mapGetters({
      corp: 'auth/corp',
      user: 'auth/user',
      softwareId: 'auth/softwareId',
    }),
  },
  mounted() {
    this.meetList();
  },
  methods: {
    async meetList() {
      const response = await axios.post("http://177.11.49.135:4000/DateRooms/rooms-from-date", {
        corpId: this.corp.id,
        softwareId: this.softwareId,
        userId: this.user.nick
      });
      const events = [];
      for (const room of response.data) {
        const listUser = await this.getUserList(room.name);
        const toDateObj = moment.utc(room.toDate);
        const fromDateObj = moment.utc(room.fromDate);
        const date = toDateObj.format('YYYY-MM-DD');
        const startTime = fromDateObj.format('HH:mm');
        const endTime = toDateObj.format('HH:mm');
        events.push({
          id: room.id,
          name: room.name,
          date: date,
          start: `${date}T${startTime}`,
          end: `${date}T${endTime}`,
          src: room.src,
          userOption: room.userOption,
          selectedUsers: listUser,
          color: this.getColor(date, startTime),
          creator: room.creator
        });
      }

      this.events = events;
    },
    async getUserList(name: string) {
      const responseA = await axios.post("http://177.11.49.135:4000/DateRooms/date-rooms", {
        name: name,
        corpId: this.corp.id,
        softwareId: this.softwareId
      });
      return responseA.data;
    },
    getColor(date, startTime) {
      const colors = ['#3F51B5', '#5C6BC0', '#9C27B0', '#AB47BC'];
      const redColor = '#FF0000';
      const now = new Date();
      const currentDate = now.toISOString().split('T')[0]; // Formato YYYY-MM-DD
      const currentTime = now.getTime(); // Tiempo en milisegundos
      const isToday = date === currentDate;
      const startTimeDate = new Date(`${date}T${startTime}`);
      const startTimeInMillis = startTimeDate.getTime();
      const timeDifference = startTimeInMillis - currentTime;
      const lessThan30Minutes = timeDifference <= 30 * 60 * 1000 && timeDifference > 0; // 30 minutos en milisegundos
      if (isToday && lessThan30Minutes) {
        return redColor;
      }
      return colors[Math.floor(Math.random() * colors.length)];
    },
    setSelectedDate(dateParam: string) {
      if (dateParam) {
        const date = new Date(dateParam);
        date.setDate(date.getDate() + 1);
        this.selectedDate = date;
      }
    },
    showEventDetails(event: any) {
      this.selectedEvent = event.eventParsed.input;
      this.dialog = true;
    },
    saveNewEvent() {
      if (this.$refs.form.validate()) {
        this.calendarEvents.push({ ...this.newEvent });
        this.newEventDialog = false;
      }
    },
    async onEventClick(event) {
      this.selectedEvent = event.eventParsed.input;
      this.isToNew = false;
      this.dialog = true;
    },
    async deleteEvent(event) {
      this.eventToDelete = event;
      this.confirmDeleteDialog = true;
    },
    async confirmDelete() {
      await axios.delete("http://177.11.49.135:4000/DateRooms/", {
        data: {
          corpId: this.corp.id,
          name: this.eventToDelete.name,
          softwareId: this.softwareId
        }
      });
      this.confirmDeleteDialog = false,
        this.dialog = false;
      this.meetList();
    },
    prevDay() {
      const currentDate = new Date(this.selectedDate);
      currentDate.setDate(currentDate.getDate() - 1);
      const day = currentDate.getDate()
      const month = currentDate.getMonth() + 1
      const year = currentDate.getFullYear()
      this.selectedDate = `${year}-${month}-${day}`;;
    },
    nextDay() {
      const currentDate = new Date(this.selectedDate);
      currentDate.setDate(currentDate.getDate() + 1);
      const day = currentDate.getDate()
      const month = currentDate.getMonth() + 1
      const year = currentDate.getFullYear()
      this.selectedDate = `${year}-${month}-${day}`;;
    },
    async copyEvent() {
      const eventDetails = `
      ${this.$t('misc.name')}: ${this.selectedEvent.name}
      ${this.$t('misc.beginning')}: ${this.selectedEvent.start.replace('T', ' ')}
      ${this.$t('misc.end')}: ${this.selectedEvent.end.replace('T', ' ')}
      ${this.$t('configurationSup.user')}: ${this.selectedEvent.selectedUsers?.join(', ')}
      ${this.$t('misc.creator')}: ${this.selectedEvent.creator}
      ${this.$t('misc.link')}: https://meet.voipgroup.com/${this.softwareId}${this.corp.id}/${this.selectedEvent.name}`;

      const textarea = document.createElement('textarea');
      textarea.value = eventDetails;
      document.body.appendChild(textarea);
      textarea.select();
      try {
        navigator.clipboard.writeText(textarea.value);
        // this.$toast.success(this.$t('misc.copiedToClipboard'));
      } catch (error) {
        console.error('Error copying to clipboard:', error);
        // this.$toast.error(this.$t('misc.copyError'));
      }
      document.body.removeChild(textarea);
    }
  }
});
