

import Vue from "vue";
import i18n from "@/plugins/i18n";

import CSV from "@/components/templates/CSV.vue";
import CopyButton from "@/components/templates/CopyButton.vue";
import Top from "@/components/templates/Top.vue";
import ResumePollsChart from "./ResumePollsChart.vue";

export default Vue.extend({
  name: "PollsStatisticsResume",
  props: ["list", "questionList", "to", "from", "summarized", "exten"],

  data: () => ({
    search: "",
    stateIcons: [] as string[],
    playingSound: [] as HTMLAudioElement[],
    loadingList: false,

    csvKey: 0
  }),

  watch: {
    list(newVal) {
      this.csvKey += 1;
    },
    summarized(newVal) {
      this.csvKey += 1;
    }
  },

  components: {
    CSV,
    CopyButton,
    Top,
    ResumePollsChart
},

  computed: {
    computedPollList(): any[] {
      return this.list.map((el: any) => ({
        ...el,
        questionId: this.questionList.find((e: any) => el.questionId == e.value).text
      }))
    }
  },

  methods: {
    headers() {
      return !!this.list[0]?.exten ?
      [
        {
          text: i18n.t("misc.actions"),
          value: "actions",
          sortable: false,
          align: "left",
          width: "10%",
        },
        {
          text: i18n.t("misc.quantity"),
          value: "cantidad",
        },
        { 
          text: i18n.t("configuration.extension"),
          value: "exten"
        },
        { 
          text: "Moda",
          value: "moda"
        },
        { 
          text: "Media",
          value: "media"
        },
        { 
          text: i18n.t("misc.question"),
          value: "questionId"
        },
      ]
      :
      [
        {
          text: i18n.t("misc.actions"),
          value: "actions",
          sortable: false,
          align: "left",
          width: "10%",
        },
        {
          text: i18n.t("misc.quantity"),
          value: "cantidad",
        },
        { 
          text: "Moda",
          value: "moda"
        },
        { 
          text: "Media",
          value: "media"
        },
        { 
          text: i18n.t("misc.question"),
          value: "questionId"
        },
      ];
    },
  },
});
