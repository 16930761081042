
import Vue from "vue";

import config from "../../../config.js";

import CSV from "@/components/templates/CSV.vue";
import CopyButton from "@/components/templates/CopyButton.vue";
import { mapGetters } from "vuex";
import i18n from "@/plugins/i18n";

export default Vue.extend({
  name: "CDRStatisticsResume",
  props: {
    list: {
      type: Array,
      required: true,
    },
    detailed: {
      type: Boolean,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      search: "",
      stateIcons: [] as string[],
      playingSound: [] as HTMLAudioElement[],
      loadingList: false,

      csvKey: 0
    };
  },

  components: {
    CSV,
    CopyButton,
  },

  computed: {
    ...mapGetters({
      user: "auth/corp",
    }),

    computedCDRList() {
      let cdrCache = this.list as any;

      cdrCache = cdrCache.map((el: any) => ({
          ...el,
          origin: el.origin ? el.origin.includes("_") ? el.origin.split("_")[1]  : el.origin : "",
          caller: el.caller ? el.caller.includes("_") ? el.caller.split("_")[1] : el.caller : "",
          destination: el.destination ? el.destination.includes("_") ? el.destination.split("_")[1] : el.destination : "",
          date: this.dateFormat(el.date)
        })
      )
      
      return cdrCache;
    }
  },

  watch: {
    list(newList) {
      // This make the CSV rerender when the list change, so the csv headers change too.
      this.csvKey += 1;

      this.loadingList = true;
      this.stateIcons = [];
      this.playingSound = [];
      
      for (let i = 0; i < newList.length; i++) {
        this.stateIcons.push("mdi-play");
      }
      
      this.playingSound.length = newList.length;
      this.loadingList = false;
    }
  },
  mounted() {
    this.loadingList = true;
    for (let i = 0; i < this.list.length; i++) {
      this.stateIcons.push("mdi-play");
    }
    this.playingSound.length = this.list.length;
    this.loadingList = false;
  },
  
  methods: {

    customSort(items: any[], index: string[], isDesc: boolean[]) {
      return items.sort((a: any, b: any) => {
        if (index[0] == "date") {
          
          let originalDateA: any = this.list.find((e: any) => this.dateFormat(e.date) == a.date);
          let originalDateB: any = this.list.find((e: any) => this.dateFormat(e.date) == b.date);

          let dateA: any = new Date(originalDateA.date)
          let dateB: any = new Date(originalDateB.date)

          return !isDesc[0] ? dateB - dateA : dateA - dateB;
        }
        else if(typeof a[index[0]] !== 'undefined'){

          let firstItem = a[index[0]].toString().toLowerCase();
          let secondItem = b[index[0]].toString().toLowerCase();

          if (!isDesc[0]) return firstItem.localeCompare(secondItem);
          else return secondItem.localeCompare(firstItem);
        }
      });
    },

    detailedHeaders() {
      // 1 = Efectuada.
      return this.type == 1
        ? [
            {
              text: this.$i18n.t("misc.date"),
              value: "date",
            },
            {
              text: this.$i18n.t("misc.origin"),
              value: "caller",
            },
            {
              text: this.$i18n.t("misc.destination"),
              value: "dialed",
            },
            { 
              text: this.$i18n.t("misc.destinationType"), 
              value: "name"
            },
            {
              text: this.$i18n.t("misc.duration"),
              value: "duration",
            },
            this.user.costEnabled ?
            {
              text: i18n.t("misc.cost"),
              value: "usercost"
            } : "",
            {
              text: this.$i18n.t("misc.audio"),
              width: "90px",
              value: "recordFile",
              align: "center"
            },
          ]
        : [
            {
              text: this.$i18n.t("misc.date"),
              value: "date",
            },
            {
              text: this.$i18n.t("misc.origin"),
              value: "caller",
            },
            {
              text: this.$i18n.t("misc.dialed"),
              value: "dialed",
            },
            {
              text: this.$i18n.t("misc.destination"),
              value: "destination",
            },
            { 
              text: this.$i18n.t("misc.destinationType"), 
              value: "name"
            },
            {
              text: this.$i18n.t("misc.duration"),
              value: "duration",
            },
            {
              text: this.$i18n.t("misc.audio"),
              width: "90px",
              value: "recordFile",
              align: "center"
            },
          ];
    },
    headers() {
      return [
          {
            text: this.$i18n.t("misc.origin"),
            value: "caller",
          },
          {
            text: this.$i18n.t("misc.quantity"),
            value: "quantidade",
          },
          { 
            text: this.$i18n.t("misc.destinationType"), 
            value: "name" 
          },
          {
            text: this.$i18n.t("misc.duration"),
            value: "duration",
          },
          // {
          //   text: this.$i18n.t("misc.quantity"),
          //   width: "10%",
          //   value: "quantidade",
          // },
        ]
    },

    dateFormat(date: string) {
      let myDate = new Date(date).toLocaleString(this.$i18n.locale);
      return myDate ? myDate.split(",")[0] + " " + myDate.split(",")[1] : ""; 
    },

    // dateFormat(date: string) {
    //   let myDate = new Date(date).toUTCString();
    //   return myDate.slice(0, myDate.length - 3)
    // },


    setPlay(item: any) {
      let index = this.list.findIndex((el: any) => el.recordFile == item.recordFile) 
      
      let icon = this.stateIcons[index];

      if (icon === "mdi-play") {
        icon = "mdi-pause";
        this.playSound(item);
      } else {
        icon = "mdi-play";
        this.pauseSound(item);
      }

      this.stateIcons[index] = icon;
    },

    async playSound(val: any) {
      const index = this.list.indexOf(val);
      const file = val.recordFile;
      const url = config.url + "cdrs/getsound/" + file;
      const token = localStorage.getItem("token");
      const result = await fetch(url, {
        credentials: "same-origin",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const blob = await result.blob();
      if (blob) {
        const url = URL.createObjectURL(blob);
        this.playingSound[index] = new Audio(url);
        this.playingSound[index].addEventListener("ended", () => {
          this.stateIcons[index] = "mdi-play";
        });
        this.playingSound[index].play();
      } else {
        alert("Cannot load");
      }
    },

    pauseSound(val: any) {
      const index = this.list.indexOf(val);
      this.playingSound[index].pause();
    },

    async downloadFile(item: any) {
      const file = item.recordFile;
      const url = config.url + "cdrs/getsound/" + file;
      const token = localStorage.getItem("token");

      const response = await fetch(url, {
        credentials: "same-origin",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const blob = await response.blob();
      const link: HTMLAnchorElement = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = item.recordFile as string;
      link.click();
      URL.revokeObjectURL(link.href);
    },
  },
});
