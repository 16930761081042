

import Vue from "vue";

import Top from "@/components/templates/Top.vue";
import CDRStatisticsResume from "./CDRStatisticsResume.vue";

import i18n from "@/plugins/i18n";
import * as action from "@/store/actions";
import { mapActions, mapGetters } from "vuex";
import { Cdr, CdrRequest, Extension } from "@/types/conf";

export default Vue.extend({
  name: "CDRStatisticsSearch",

  data() {
    return {

      dateFromModel: false,
      dateFrom: new Date().toJSON().slice(0, 10),
      hourFrom: "00:00",
      dateToModel: false,
      dateTo: new Date().toJSON().slice(0, 10),
      hourToModel: false,
      hourTo: "23:59",

      valid: false,
      menu: false,
      loading: false,
      searched: false,
      results: [] as Cdr[],
      extenList: [] as Extension[],

      request: {
        from: "",
        to: "",
        detailed: false,
        filter: "",
        type: 1,
      } as CdrRequest,
      rules: {
        required: (v: any) => !!v || i18n.t("loginView.required"),
      },
    };
  },
  computed: {
    ...mapGetters({
      views: "auth/views",
      hoverColor: "lookAndFeel/hoverColor",
      menuIconColor: "lookAndFeel/menuIconColor",
      menuFontColor: "lookAndFeel/menuFontColor",
    }),
    compExtenList(): any {
      return this.extenList.length > 0
        ? [
            {
              id: 1,
              number: i18n.t("misc.outgoing"),
            },
            {
              id: 2,
              number: i18n.t("misc.incoming"),
            },
            {
              number: i18n.t("configuration.extension"),
              disabled: true,
            },
            ...this.extenList,
          ]
        : [
            {
              id: 1,
              number: i18n.t("misc.outgoing"),
            },
            {
              id: 2,
              number: i18n.t("misc.incoming"),
            },
          ];
    },
  },
  components: {
    CDRStatisticsResume,
    Top,
  },
  async mounted() {
    this.extenList = await this.getExtensionsList(action.EXTENSION_CONTROLLER);

    this.onSubmit();
  },
  methods: {
    ...mapActions({
      getCDRList: "crudService/getCDRList",
      getExtensionsList: "crudService/getList",
    }),
    dateRules() {
      return this.dateFrom < this.dateTo ? true : i18n.t("rules.dateError");
    },
    async onSubmit() {
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        this.loading = !this.loading;

        const fromFormat = this.dateFrom + "T" + this.hourFrom;
        const toFormat = this.dateTo + "T" + this.hourTo;
        const arr = this.extenList.filter((ex) => ex.id === this.request.type);

        const body = {
          from: fromFormat,
          to: toFormat,
          detailed: !!this.request.detailed,
          filter: !!this.request.type && this.request.type >= 3 ? arr[0].number?.toString() : "",
          type: !!this.request.type && this.request.type > 3 ? 3 : this.request.type,
        };

        this.results = await this.getCDRList(body);
        this.loading = !this.loading;
        if (!!this.results) this.searched = true;
      }
    },
  },
});
