var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (
      !_vm.loading && _vm.$route.name !== 'login' && _vm.user != null && _vm.user.level != 5
    )?_c('v-navigation-drawer',{style:(_vm.$vuetify.theme.dark
        ? { backgroundColor: 'black' }
        : { backgroundColor: _vm.menuColor }),attrs:{"mini-variant":_vm.mini,"width":"200","app":"","dark":"","permanent":""},on:{"update:miniVariant":function($event){_vm.mini=$event},"update:mini-variant":function($event){_vm.mini=$event}}},[_c('v-card',{staticClass:"mt-5",staticStyle:{"display":"flex","flex-direction":"column"},attrs:{"height":"96%","elevation":"0"}},[_c('v-hover',{staticClass:"pr-8",style:(_vm.$vuetify.theme.dark
            ? { backgroundColor: 'black' }
            : { backgroundColor: _vm.menuColor })},[_c('v-img',{attrs:{"src":_vm.loginBottomImageLocal,"contain":"","transition":"scale-transition"}})],1),_c('v-list',{staticClass:"navList",style:(_vm.$vuetify.theme.dark
            ? { backgroundColor: 'black' }
            : { backgroundColor: _vm.menuColor })},[_c('div',[(_vm.user.level === 1 && _vm.nameCorp)?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-list-item',{class:{ active: _vm.selectedCorpActive },style:(hover ? _vm.computedHover : null),on:{"click":function($event){return _vm.selectItem('selectedCorp')}}},[_c('v-list-item-icon',{staticClass:"iconContainer"},[_c('v-icon',{attrs:{"color":'red' ? ' red ' : _vm.menuIconColorLocal,"title":"Exit"},on:{"click":function($event){return _vm.switchCorp(0, '')}}},[_vm._v("mdi-close")])],1),_c('v-list-item-subtitle',{staticClass:"font-weight-bold",style:(_vm.$vuetify.theme.dark
                    ? 'color:white'
                    : 'color:' + _vm.menuFontColorLocal)},[_vm._v(" "+_vm._s(_vm.nameCorp)+" ")]),_c('v-icon',{staticStyle:{"transform":"rotate(-90deg)"},attrs:{"color":_vm.$vuetify.theme.dark ? ' #fff ' : _vm.menuIconColorLocal}},[_vm._v("mdi-chevron-down")])],1)]}}],null,false,1942344438)}):_vm._e(),(_vm.user.level === 1 && _vm.nameCorp)?_c('div',[_c('v-list-group',{staticClass:"mb-0",attrs:{"color":_vm.$vuetify.theme.dark ? '#fff ' : _vm.menuIconColorLocal},model:{value:(_vm.selectedCorpCollapsed),callback:function ($$v) {_vm.selectedCorpCollapsed=$$v},expression:"selectedCorpCollapsed"}},[_c('v-virtual-scroll',{attrs:{"bench":10,"items":_vm.configurationSup,"item-height":"40"},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('v-hover',{staticStyle:{"margin-left":"-13px"},scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-list-item',{class:{
                        active: 'selected ' + item.route === _vm.activeSubItem,
                      },style:(hover ? _vm.computedHover : null),attrs:{"dense":"","link":"","sub-group":""},on:{"click":function($event){return _vm.selectItem('selected ' + item.route)}}},[_c('v-list-item-subtitle',{staticClass:"pl-4 font-weight-bold",style:(_vm.$vuetify.theme.dark
                            ? 'color:white'
                            : 'color:' + _vm.menuFontColorLocal)},[_vm._v(" "+_vm._s(_vm.$t(`configurationSup.${item.id}`))+" ")])],1)]}}],null,true)})]}}],null,false,2116765630)})],1)],1):_vm._e(),(_vm.user.level === 8)?_c('v-list-group',{attrs:{"content-class":"elevation-0","color":_vm.$vuetify.theme.dark ? ' #fff ' : _vm.menuIconColorLocal},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',{staticClass:"iconContainer"},[_c('v-icon',{attrs:{"color":!!_vm.$vuetify.theme.dark ? ' #fff ' : _vm.menuIconColorLocal}},[_vm._v(" mdi-office-building ")])],1),_c('v-list-item-subtitle',{staticClass:"font-weight-bold",style:(_vm.$vuetify.theme.dark
                    ? 'color:white'
                    : 'color:' + _vm.menuFontColorLocal)},[_vm._v(" "+_vm._s(_vm.$t('permissions.selectCorp'))+" ")])]},proxy:true},{key:"appendIcon",fn:function(){return [_c('v-icon',{attrs:{"color":!!_vm.$vuetify.theme.dark ? ' #fff ' : _vm.menuIconColorLocal}},[_vm._v(" mdi-chevron-down ")])]},proxy:true}],null,false,4294962486),model:{value:(_vm.selectedCorp),callback:function ($$v) {_vm.selectedCorp=$$v},expression:"selectedCorp"}},[_c('v-virtual-scroll',{attrs:{"bench":10,"items":_vm.corpList,"height":"200","item-height":"40"},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-list-item',{key:item.id,staticClass:"pr-10",style:(hover ? _vm.computedHover : null),on:{"click":function($event){return _vm.switchCorp(item.id, item.name)}}},[_c('v-list-item-subtitle',{staticClass:"pl-4 font-weight-bold",style:(_vm.$vuetify.theme.dark
                          ? 'color:white'
                          : 'color:' + _vm.menuFontColorLocal)},[_vm._v(" "+_vm._s(item.name)+" ")])],1)]}}],null,true)})]}}],null,false,531233626)})],1):_vm._e(),_c('div',{staticStyle:{"margin":"1rem 0"}},[_c('v-divider',{staticClass:"my-3"}),(_vm.user.level === 1)?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-list-item',{class:{ active: _vm.dashboardActive },style:(hover ? _vm.computedHover : null),on:{"click":function($event){return _vm.selectItem('home')}}},[_c('v-list-item-icon',{staticClass:"iconContainer"},[_c('v-icon',{attrs:{"color":_vm.$vuetify.theme.dark ? ' #fff ' : _vm.menuIconColorLocal}},[_vm._v(" mdi-home ")])],1),_c('v-list-item-subtitle',{staticClass:"font-weight-bold",style:(!!_vm.$vuetify.theme.dark
                      ? 'color:white'
                      : 'color:' + _vm.menuFontColorLocal)},[_vm._v(" "+_vm._s(_vm.$t('panel.dashboard'))+" ")])],1)]}}],null,false,2914398048)}):_vm._e(),(_vm.user.level == 3)?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-list-item',{class:{ active: _vm.dashboardActive },style:(hover ? _vm.computedHover : null),on:{"click":function($event){return _vm.selectItem('home')}}},[_c('v-list-item-icon',{staticClass:"iconContainer"},[_c('v-icon',{attrs:{"color":_vm.$vuetify.theme.dark ? ' #fff ' : _vm.menuIconColorLocal}},[_vm._v(" mdi-home ")])],1),_c('v-list-item-subtitle',{staticClass:"font-weight-bold",style:(_vm.$vuetify.theme.dark
                      ? 'color:white'
                      : 'color:' + _vm.menuFontColorLocal)},[_vm._v(" "+_vm._s(_vm.$t('configuration.home'))+" ")])],1)]}}],null,false,1024831021)}):_vm._e(),(_vm.rooms == 1)?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-list-item',{class:{ active: _vm.salasActive },style:(hover ? _vm.computedHover : null),on:{"click":function($event){_vm.menu =! _vm.menu}}},[_c('v-list-item-icon',{staticClass:"iconContainer"},[_c('v-icon',{attrs:{"color":_vm.$vuetify.theme.dark ? '#fff' : _vm.menuIconColorLocal}},[_vm._v(" mdi-video-plus ")])],1),_c('v-list-item-subtitle',{staticClass:"font-weight-bold",style:(_vm.$vuetify.theme.dark
              ? 'color:white'
              : 'color:' + _vm.menuFontColorLocal)},[_vm._v(" "+_vm._s(_vm.$t('salas.Salas'))+" ")])],1)]}}],null,false,2156857078)}):_vm._e(),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-list-group',{model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-virtual-scroll',{attrs:{"bench":2,"items":['rooms', 'meets'],"height":80,"item-height":"40"},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-list-item',{class:{ active: item === _vm.activeSubItem },style:(hover ? _vm.computedHover : null),attrs:{"dense":"","link":"","sub-group":""},on:{"click":function($event){return _vm.selectItem(item)}}},[_c('v-list-item-subtitle',{staticClass:"pl-10 font-weight-bold",style:(_vm.$vuetify.theme.dark
                  ? 'color:white'
                  : 'color:' + _vm.menuFontColorLocal)},[_vm._v(" "+_vm._s(_vm.$t(`misc.${item}`))+" ")])],1)]}}],null,true)})]}}],null,true)})],1)]}}],null,false,3554703407)}),(_vm.user.level == 3)?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-list-item',{class:{ active: _vm.dashboardActive },style:(hover ? _vm.computedHover : null),on:{"click":function($event){return _vm.selectItem('panel')}}},[_c('v-list-item-icon',{staticClass:"iconContainer"},[_c('v-icon',{attrs:{"color":_vm.$vuetify.theme.dark ? ' #fff ' : _vm.menuIconColorLocal}},[_vm._v(" mdi-picture-in-picture-top-right ")])],1),_c('v-list-item-subtitle',{staticClass:"font-weight-bold",style:(_vm.$vuetify.theme.dark
                      ? 'color:white'
                      : 'color:' + _vm.menuFontColorLocal)},[_vm._v(" "+_vm._s(_vm.$t('configuration.panel'))+" ")])],1)]}}],null,false,3825866222)}):_vm._e(),(_vm.user.level == 3)?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-list-item',{class:{ active: _vm.dashboardActive },style:(hover ? _vm.computedHover : null),on:{"click":function($event){return _vm.selectItem('activeCalls')}}},[_c('v-list-item-icon',{staticClass:"iconContainer"},[_c('v-icon',{attrs:{"color":_vm.$vuetify.theme.dark ? '#fff' : _vm.menuIconColorLocal}},[_vm._v(" mdi-phone-voip ")])],1),_c('v-list-item-subtitle',{staticClass:"font-weight-bold",style:(_vm.$vuetify.theme.dark
                      ? 'color:white'
                      : 'color:' + _vm.menuFontColorLocal)},[_vm._v(" "+_vm._s(_vm.$t('misc.activeCalls'))+" ")])],1)]}}],null,false,93691287)}):_vm._e(),(_vm.user.level === 3 || _vm.user.level === 4)?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-list-item',{class:{ active: _vm.statsActive },style:(hover ? _vm.computedHover : null),on:{"click":function($event){return _vm.selectItem('statistics')}}},[_c('v-list-item-icon',{staticClass:"iconContainer"},[_c('v-icon',{attrs:{"color":_vm.$vuetify.theme.dark ? '#fff' : _vm.menuIconColorLocal}},[_vm._v(" mdi-finance ")])],1),_c('v-list-item-subtitle',{staticClass:"font-weight-bold",style:(_vm.$vuetify.theme.dark
                      ? 'color:white'
                      : 'color:' + _vm.menuFontColorLocal)},[_vm._v(" "+_vm._s(_vm.$t('configuration.statistics'))+" ")]),_c('v-icon',{staticStyle:{"transform":"rotate(-90deg)"},attrs:{"color":!!_vm.$vuetify.theme.dark ? ' #fff ' : _vm.menuIconColorLocal}},[_vm._v("mdi-chevron-down")])],1)]}}],null,false,479426066)}):_vm._e(),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover, isActive }){return [(_vm.user.level == 3 || _vm.user.level === 4)?_c('v-list-group',{staticClass:"mb-0",style:(hover & isActive ? _vm.computedHover : null),attrs:{"color":!!_vm.$vuetify.theme.dark ? ' #fff ' : _vm.menuIconColorLocal},scopedSlots:_vm._u([{key:"appendIcon",fn:function(){return [_c('v-icon',{attrs:{"color":_vm.$vuetify.theme.dark ? '#fff' : _vm.menuIconColorLocal}},[_vm._v(" mdi-chevron-down ")])]},proxy:true}],null,true),model:{value:(_vm.statsCollapsed),callback:function ($$v) {_vm.statsCollapsed=$$v},expression:"statsCollapsed"}},[_c('v-virtual-scroll',{attrs:{"bench":10,"items":_vm.compStatisticsHeaders,"height":_vm.compStatisticsHeaders.length * 40,"item-height":"40"},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-list-item',{class:{ active: item.id === _vm.activeSubItem },style:(hover ? _vm.computedHover : null),attrs:{"dense":""},on:{"click":function($event){return _vm.selectItem(item.id)}}},[_c('v-list-item-subtitle',{staticClass:"pl-10 font-weight-bold",style:(_vm.$vuetify.theme.dark
                              ? 'color:white'
                              : 'color:' + _vm.menuFontColorLocal)},[_vm._v(" "+_vm._s(_vm.$t(`statistics.${item.text}`))+" ")])],1)]}}],null,true)})]}}],null,true)})],1):_vm._e()]}}],null,false,3572491253)}),(_vm.user.level === 3 || _vm.user.level === 1 || _vm.user.level === 4)?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-list-item',{class:{ active: _vm.configActive },style:(hover ? _vm.computedHover : null),on:{"click":function($event){return _vm.selectItem('configuration')}}},[_c('v-list-item-icon',{staticClass:"iconContainer"},[_c('v-icon',{attrs:{"color":_vm.$vuetify.theme.dark ? '#fff' : _vm.menuIconColorLocal}},[_vm._v(" mdi-cog ")])],1),_c('v-list-item-subtitle',{staticClass:"font-weight-bold",style:(_vm.$vuetify.theme.dark
                      ? 'color:white'
                      : 'color:' + _vm.menuFontColorLocal)},[_vm._v(" "+_vm._s(_vm.$t('configuration.config'))+" ")]),_c('v-icon',{staticStyle:{"transform":"rotate(-90deg)"},attrs:{"color":_vm.$vuetify.theme.dark ? ' #fff ' : _vm.menuIconColorLocal}},[_vm._v("mdi-chevron-down")])],1)]}}],null,false,3172224128)}):_vm._e(),_c('v-hover',[_c('v-list-group',{model:{value:(_vm.configCollapsed),callback:function ($$v) {_vm.configCollapsed=$$v},expression:"configCollapsed"}},[_c('v-virtual-scroll',{attrs:{"bench":10,"items":_vm.compConfigHeaders,"height":_vm.user.level === 3 ? 520 : 450,"item-height":"40"},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-list-item',{class:{ active: item.route === _vm.activeSubItem },style:(hover ? _vm.computedHover : null),attrs:{"dense":"","link":"","sub-group":""},on:{"click":function($event){return _vm.selectItem(item.route)}}},[_c('v-list-item-subtitle',{staticClass:"pl-10 font-weight-bold",style:(_vm.$vuetify.theme.dark
                              ? 'color:white'
                              : 'color:' + _vm.menuFontColorLocal)},[_vm._v(" "+_vm._s(_vm.$t(`configuration.${item.id}`))+" ")])],1)]}}],null,true)})]}}],null,false,2431836891)})],1)],1),(_vm.user.level === 1)?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-list-item',{class:{ active: _vm.logmonActive },style:(hover ? _vm.computedHover : null),on:{"click":function($event){return _vm.selectItem('logmon')}}},[_c('v-list-item-icon',{staticClass:"iconContainer"},[_c('v-icon',{attrs:{"color":_vm.$vuetify.theme.dark ? ' #fff ' : _vm.menuIconColorLocal}},[_vm._v(" mdi-monitor ")])],1),_c('v-list-item-subtitle',{staticClass:"font-weight-bold",style:(!!_vm.$vuetify.theme.dark
                      ? 'color:white'
                      : 'color:' + _vm.menuFontColorLocal)},[_vm._v(" "+_vm._s('Logmon')+" ")])],1)]}}],null,false,999946605)}):_vm._e(),_c('v-divider',{staticClass:"my-3"})],1)],1),_c('div',[_c('v-list-item',{staticStyle:{"background-color":"#f5f5f515","justify-content":"center","align-items":"center","max-height":"30px"},on:{"click":function($event){$event.stopPropagation();return _vm.collapseSubItems.apply(null, arguments)}}},[(_vm.mini)?_c('v-icon',{attrs:{"large":"","color":_vm.$vuetify.theme.dark ? '#fff' : _vm.menuIconColorLocal}},[_vm._v(" mdi-chevron-right ")]):_vm._e(),(!_vm.mini)?_c('v-icon',{attrs:{"large":"","color":_vm.$vuetify.theme.dark ? '#fff' : _vm.menuIconColorLocal}},[_vm._v(" mdi-chevron-left ")]):_vm._e()],1),_c('div',{style:(_vm.$vuetify.theme.dark
                ? { color: 'white' }
                : { color: _vm.menuFontColorLocal })},[_c('v-text',{staticStyle:{"margin":"150px"}},[_c('center',[_vm._v(_vm._s(_vm.corp.name))])],1)],1)],1)])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }