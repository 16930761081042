

import Vue from "vue";
import axios from "axios";

import * as action from "../../store/actions"
import { tokenHeader } from "../../services/auth";
import i18n from "@/plugins/i18n";

export default Vue.extend({
  name: "ResumePollsChart",
  props: ["exten"],
  components: {
  },

  data() {
    return {
      dialog: false,
      ipArray: [],
    }
  },

  methods: {
    async getData() {
      const headers = tokenHeader();

      await axios
        .get(`${action.EXTENSION_IP}${this.exten}`, { withCredentials: true, headers })
        .then((res) => {
          this.ipArray = res.data;
        })
        .catch(() => {
          alert("EXTENSION_IP is not working");
        });
    }
  },

    
});

