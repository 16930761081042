

import Vue from "vue";
import * as action from "@/store/actions";
import axios from "axios";

import { mapGetters } from "vuex";

export default Vue.extend({
  data: () => ({
    darkMode: false,
  }),

  computed: {
    ...mapGetters({
      menuIconColor: "lookAndFeel/menuIconColor",
    }),
  },

  methods: {

    async toggleDarkMode() {
      let headers = {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Credentials': 'true',
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      };

      await axios.post(action.SET_USER_THEME, { theme: +!this.$vuetify.theme.dark }, { withCredentials: true, headers })
        .then(() => {
          this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
          this.darkMode = !this.darkMode;
        })
      
    },
  },
});

