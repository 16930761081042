
import Vue from 'vue';
import axios from 'axios';

import { mapGetters, mapActions } from 'vuex';
import { mainBus } from '@/main';
import * as action from '@/store/actions';

import { Corp, MyUser, View } from '@/types/conf';
import VueI18n from 'vue-i18n';
import Panel from '@/plugins/panelHub';
import {
  eraseCookie,
  getCookie,
  setCookie,
} from '@/plugins/utils/cookieManager';

import config from '@/config.js';
import NavGroup from './NavGroup.vue';
import { minutesToMilliseconds } from 'date-fns';

export default Vue.component('NavigationDrawer', {
  components: {
    NavGroup,
  },
  data: () => ({
    showNameCorp: !!getCookie('selectedCorpName'),
    nameCorp: '' as string | null,
    userIp: null,
    corpIp: null,
    // This controls the active sub-element in the screen.
    activeSubItem: '',
    menu: false,

    // This paint the selected item in the menu.
    selectedCorpActive: false,
    selectedCorp: false,
    dashboardActive: false,
    panelActive: false,
    statsActive: false,
    cdrActive: false,
    configActive: false,
    logmonActive: false,
    aboutActive: false,
    salasActive: false,
    confirmView: false,

    selectedCorpCollapsed: false,
    corpCollapsed: false,
    configCollapsed: false,
    statsCollapsed: false,
    panelCollapsed: false,

    loading: false,
    userTemp: null as MyUser | null,
    hoverColorLocal: '',
    menuIconColorLocal: '',
    menuFontColorLocal: '',
    loginBottomImageLocal: '',
    mini: true,

    configurationSup: [
      {
        route: 'extension',
        id: 'extension',
        level: '1',
      },
      {
        route: 'speedDialCorp',
        id: 'speedDialCorp',
        level: '3',
      },
      {
        route: 'ivr',
        id: 'ivr',
        level: '1',
      },
      {
        route: 'queues',
        id: 'queues',
        level: '1',
      },
      {
        route: 'polls',
        id: 'polls',
        level: '1',
      },
      {
        route: 'voiceMail',
        id: 'voiceMail',
        level: '1',
      },
      {
        route: 'mohCorp',
        id: 'mohCorp',
        level: '1',
      },
      // {
      //   route: "trunk",
      //   id: "trunk",
      //   level: "10"
      // },
      {
        route: 'time',
        id: 'time',
        level: '1',
      },
      // {
      //   route: "CDRlist",
      //   id: "CDRlist",
      //   level: "10"
      // },
    ],
    configuration: [
      {
        route: 'extension',
        id: 'extension',
        level: '3,4',
      },
      {
        route: 'corp',
        id: 'corp',
        level: '1',
      },
      {
        route: 'ivr',
        id: 'ivr',
        level: '3',
      },
      {
        route: 'queues',
        id: 'queues',
        level: '3',
      },
      {
        route: 'speedDialCorp',
        id: 'speedDialCorp',
        level: '3',
      },
      {
        route: 'time',
        id: 'time',
        level: '3',
      },
      {
        route: 'user',
        id: 'user',
        level: '1,3',
      },
      {
        route: 'restrictionsCorp',
        id: 'restrictionsCorp',
        level: '1',
      },
      {
        route: 'restrictionsexten',
        id: 'restrictionsExten',
        level: '3',
      },
      {
        route: 'trunk',
        id: 'trunk',
        level: '1,3',
      },
      {
        route: 'lookAndFeel',
        id: 'lookAndFeel',
        level: '1,3',
      },
      // {
      //   route: "integration",
      //   id: "integration",
      //   level: "2,3",
      // },
      {
        route: 'voiceMail',
        id: 'voiceMail',
        level: '3,4',
      },
      // {
      //   route:"cdrStats",
      //   id: "cdrStats",
      //   text: "cdrStats",
      //   level: "10"
      // },
      // {
      //   route: "dialer",
      //   id: "dialer",
      //   //Cambio nivel para ocultar
      //   level: "10",
      // },
      {
        route: 'mohCorp',
        id: 'mohCorp',
        level: '3,2',
      },
      {
        route: 'polls',
        id: 'polls',
        level: '3',
      },
      {
        route: 'mediaserver',
        id: 'mediaServer',
        level: '1,2',
      },
      {
        route: 'servers',
        id: 'server',
        level: '1',
      },
      {
        route: 'destinationGroup',
        id: 'destinationGroup',
        level: '1',
      },
      {
        route: 'siproutes',
        id: 'sipRoutes',
        level: '1',
      },
    ],
    corpList: [] as Corp[],
    statistics: [
      {
        route: 'queueStats',
        id: 'queueStats',
        text: 'queueStats',
        level: '3',
      },
      {
        route: 'pollStats',
        id: 'pollStats',
        text: 'pollStats',
        level: '3',
      },
      {
        route: 'ivrStats',
        id: 'ivrStats',
        text: 'ivrStats',
        level: '3',
      },
      // {
      //   id: "monitorQuality",
      //   text: "monitorQuality",
      //   level: "10",
      // },
      {
        route: 'cdrStats',
        id: 'cdrStats',
        text: 'cdrStats',
        level: '3,4',
      },
    ],
    panel: [
      {
        route: 'dashboard',
        id: 'dashboard',
        text: 'dashboard',
        level: '3',
      },
      // {
      //   route:"list",
      //   id: "list",
      //   text: "list",
      //   //cambio nivel para ocultar
      //   level: "10",
      // },
      // {
      //   route:"activeCalls",
      //   id: "activeCalls",
      //   text: "activeCalls",
      //   level: "3",
      // },
    ],
  }),

  computed: {
    ...mapGetters({
      user: 'auth/user',
      corp: 'auth/corp',
      softwareId: 'auth/softwareId',
      rooms: 'auth/rooms',
      hoverColor: 'lookAndFeel/hoverColor',
      menuIconColor: 'lookAndFeel/menuIconColor',
      menuFontColor: 'lookAndFeel/menuFontColor',
      loginBottomImage: 'lookAndFeel/loginBottomImage',
      menuColor: 'lookAndFeel/menuColor',
    }),

    computedHover(): string {
      return 'background-color: ' + this.hoverColorLocal;
    },

    compStatisticsHeaders(): { id: string; text: VueI18n.TranslateResult }[] {
      return this.statistics
        .filter((conf) => (conf.level.includes(this.user.level) ? true : false))
        .sort((a, b) => {
          if (
            this.$i18n.t(`statistics.${a.text}`) <
            this.$i18n.t(`statistics.${b.text}`)
          ) {
            return -1;
          }
          if (
            this.$i18n.t(`statistics.${a.text}`) >
            this.$i18n.t(`statistics.${b.text}`)
          ) {
            return 1;
          }
          return 0;
        });
    },

    compPanelHeaders(): {
      id: string;
      level: string;
      text: VueI18n.TranslateResult;
    }[] {
      // Filtrar los paneles basados en el nivel del usuario
      const filteredPanels = this.panel.filter((conf) =>
        conf.level.includes(this.user.level)
      );

      // Ordenar los paneles por el texto traducido
      filteredPanels.sort((a, b) => {
        const textA = this.$i18n.t(`panel.${a.text}`);
        const textB = this.$i18n.t(`panel.${b.text}`);
        return textA.localeCompare(textB);
      });

      return filteredPanels;
    },

    compConfigHeaders(): { route: string; id: string; level: string }[] {
      // Filtrar configuraciones basadas en el nivel del usuario
      const filteredConfigs = this.configuration
        .filter((conf) => conf.level.includes(this.user.level))
        .filter((conf) => this.user.allowCustom || conf.id !== 'lookAndFeel');

      // Ordenar las configuraciones por el texto traducido
      filteredConfigs.sort((a, b) => {
        const textA = this.$i18n.t(`configuration.${a.id}`);
        const textB = this.$i18n.t(`configuration.${b.id}`);
        return textA.localeCompare(textB);
      });

      return filteredConfigs;
    },
  },

  async mounted() {
    mainBus.$on(action.RELOAD_LOOK_AND_FEEL, () => {
      this.hoverColorLocal = this.hoverColor;
      this.menuIconColorLocal = this.menuIconColor;
      this.menuFontColorLocal = this.menuFontColor;
      this.loginBottomImageLocal = this.loginBottomImage;
    });

    if (this.user.level == 1)
      this.corpList = await this.getCorpList(action.CORP_CONTROLLER);
  },

  created() {
    mainBus.$on(action.USER_LOADED, (user: MyUser) => {
      this.userTemp = user;
    });
  },

  methods: {
    ...mapActions({
      signOut: 'auth/signOut',
      getCorpList: 'crudService/getList',
    }),
    async switchCorp(id: number, name: string) {
      this.loading = true;

      if (id == 0) this.nameCorp = '';

      setCookie('selectedCorpId', id.toString(), 1);
      setCookie('selectedCorpName', name, 1);
      this.showNameCorp = true;
      this.nameCorp = name;
      //this.$store.dispatch("lookAndFeel/checkLookandfeel");

      this.loading = false;

      this.navigateTo('home');
    },

    collapseSubItems() {
      if (!this.mini) {
        if (this.configCollapsed) {
          this.configCollapsed = !this.configCollapsed;
        }
        if (this.statsCollapsed) {
          this.statsCollapsed = !this.statsCollapsed;
        }
        if (this.selectedCorp) {
          this.selectedCorp = !this.selectedCorp;
        }
      }
      this.mini = !this.mini;
    },

    isVisible(name: string) {
      const user =
        this.user != null && this.user == 1 && this.user == 3
          ? this.user
          : this.userTemp;

      if (user) {
        const view = user.views.find((v: View) => v.name == name);

        if (view == null || view == 'undefined') return false;

        return view.actions.includes('read');
      } else return false;
    },

    navigateTo(navTo: string) {
      if (navTo != this.$route.name) {
        this.$router.replace({
          name: navTo,
          params: {
            corp: localStorage.getItem('corp') || '',
            lang: this.$i18n.locale,
          },
        });
      }
    },

    selectItem(item: string) {
      this.activeSubItem = '';

      this.selectedCorpCollapsed = false;
      this.selectedCorpActive = false;

      this.dashboardActive = false;
      this.salasActive = false;

      this.statsActive = false;
      this.statsCollapsed = false;

      this.configActive = false;
      this.configCollapsed = false;

      this.logmonActive = false;

      this.aboutActive = false;

      switch (item) {
        case 'logmon':
          this.mini = screen.width < 600;
          this.logmonActive = screen.width > 600;
          window.open(config.logmon45, '_blank');
          break;

        case 'selectedCorp':
          this.selectedCorpCollapsed = true;
          this.selectedCorpActive = true;
          break;

        case 'home':
          this.mini = screen.width < 600;
          this.dashboardActive = screen.width > 600;
          this.navigateTo(item);
          break;
        case 'rooms':
          this.mini = screen.width < 600;
          this.salasActive = screen.width > 600;
          this.navigateTo("salas");
          break;

        case 'statistics':
          this.statsCollapsed = true;
          this.statsActive = true;
          console.log(this.statsActive);

          break;

        case 'configuration':
          this.configCollapsed = true;
          this.configActive = true;
          break;

        case 'about':
          this.mini = screen.width < 600;
          this.aboutActive = screen.width > 600;
          this.navigateTo(item);
          break;
        case 'meets':
          this.mini = screen.width < 600;
          this.dashboardActive = screen.width > 600;
          this.navigateTo("calendar");
          break;

        default:
          this.mini = screen.width < 600;

          if (item.includes('selected')) item = item.slice(9);

          this.selectedCorpCollapsed = this.configurationSup.some(
            (e) => e.route == item
          );
          this.statsCollapsed = this.statistics.some(
            (e) => e.route == item && e.level.includes(this.user.level)
          );
          this.configCollapsed = this.configuration.some(
            (e) => e.route == item && e.level.includes(this.user.level)
          );
          this.activeSubItem = item;
          this.navigateTo(item);
          break;
      }
    },

    async handleSignOut() {
      await Panel.stop();

      await this.signOut();
      eraseCookie('selectedCorpId');
    },
  },
  updated() {
    this.nameCorp = getCookie('selectedCorpName');
  },
});
