

import Vue from "vue";

import CSV from "@/components/templates/CSV.vue";
import i18n from "@/plugins/i18n";
import Top from "@/components/templates/Top.vue";
import SpeedDialExtenDetail from "./SpeedDialExtenDetail.vue";

import { mapActions, mapGetters } from "vuex";

import CopyButton from "@/components/templates/CopyButton.vue";
import * as views from "@/plugins/utils/View";
import * as crud from "@/plugins/utils/Crud";
import * as action from "@/store/actions";
import { SpeedDialExtension, View } from "@/types/conf";
import store from "@/store/index";

export default Vue.extend({
  name: "SpeedDialExten",
  props: ["extenFeat"],
  data: () => ({
    canDelete: false,
    canCreate: false,
    canUpdate: false,

    alertType: "",
    alertMessage: "",
    speedDialList: [] as SpeedDialExtension[],
    selectedItem: null as SpeedDialExtension | null,
    search: "",
    dialog: false,
    dialogDelete: false,
    sortBy: "number",
    loadingList: false,
  }),
  computed: {
    ...mapGetters({
      views: "auth/views",
      hoverColor: "lookAndFeel/hoverColor",
      menuIconColor: "lookAndFeel/menuIconColor",
      menuFontColor: "lookAndFeel/menuFontColor",
    }),

    computedItems(): SpeedDialExtension[] {
      const arr = this.speedDialList ? this.speedDialList.filter(item => item.extenId === this.extenFeat.id ) : [];
      return arr;
    },
  },

  async mounted() {
    this.loadingList = true;
    this.speedDialList = await this.getSpeedDialsList(action.SPEED_DIAL_EXTENSIONS_CONTROLLER);
    this.actionHandler();
    this.loadingList = false;
  },

  async beforeRouteEnter(to, from, next) {
    await store.dispatch("lookAndFeel/setCorpName");
    const corpName = store.state.lookAndFeel.corpName as string;
    if (to.params.extenFeat) {
      next();
    } else {
      next({
        name: "extension",
        params: { corp: corpName, lang: navigator.language.slice(0, 2) },
      });
    }
  },
  components: {
    CSV,
    CopyButton,
    Top,
    SpeedDialExtenDetail
},

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    ...mapActions({
      getSpeedDialsList: "crudService/getList",
      deleteSpeedDial: "crudService/deleteObject",
    }),
    headers() {
      return [
        {
          text: i18n.t("misc.actions"),
          value: "actions",
          sortable: false,
        },
        {
          text: i18n.t("misc.num"),
          value: "number"
        },
        {
          text: i18n.t("misc.action"),
          value: "action"
        },
      ];
    },
    actionHandler() {
      const view = this.views.find((v: View) => v.name == views.SPEED_DIAL_EXTEN); 
      if (view) {
        this.canDelete = view.actions.includes(crud.DELETE);
        this.canCreate = view.actions.includes(crud.CREATE);
        this.canUpdate = view.actions.includes(crud.UPDATE);
      }
    },
    editItem(item: SpeedDialExtension) {
      this.navigateTo(item);
    },

    deleteItem(item: SpeedDialExtension) {
      this.selectedItem = item;
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      const response = await this.deleteSpeedDial({
        action: action.SPEED_DIAL_EXTENSIONS_CONTROLLER,
        id: this.selectedItem?.id,
      });
      this.closeDelete();
      if (response) {
        this.alertType = "green darken-1";
        this.alertMessage = ((i18n.t("misc.deleted") as string) +
          i18n.t("misc.defaultSuccess")) as string;
        let itemListUpdated = [...this.speedDialList];
        this.speedDialList = itemListUpdated.filter(
          (speedDial) => speedDial.id != this.selectedItem?.id
        );
      } else {
        this.alertType = "error";
        this.alertMessage = i18n.t("misc.defaultError") as string;
      }
      this.$store.dispatch("snackbar/showSnack", {
        message: this.alertMessage,
        color: this.alertType,
        timeout: 3000,
      });
    },

    closeDelete() {
      this.dialogDelete = false;
    },

    navigateTo(speedDial: any) {
      if (speedDial === "back") {
        this.$router.replace({
          name: "extension",
        });
      } else if (speedDial) {
        this.$router.replace({
          name: "speedDialExten/update",
          params: { speedDialToUpdate: speedDial, exten: this.extenFeat },
        });
      } else {
        this.$router.replace({
          name: "speedDialExten/new",
          params: { exten: this.extenFeat },
        });
      }
    },

    async refreshList() {
      this.loadingList = true;
      this.speedDialList = await this.getSpeedDialsList(action.SPEED_DIAL_EXTENSIONS_CONTROLLER);
      this.actionHandler();
      this.loadingList = false;
    },
  },
});
