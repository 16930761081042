

import Vue from "vue";

import Top from "@/components/templates/Top.vue";
import CSV from "@/components/templates/CSV.vue";
import CopyButton from "@/components/templates/CopyButton.vue";
import DialerDetail from "./DialerDetail.vue";

import { mapActions, mapGetters } from "vuex";
import config from "../../../config.js";
import i18n from "@/plugins/i18n";

import * as views from "@/plugins/utils/View";
import * as crud from "@/plugins/utils/Crud";
import * as action from "@/store/actions";
import { Dialer, Trunk, View } from "@/types/conf";

export default Vue.extend({
  name: "",
  data: () => ({
    canDelete: false,
    canCreate: false,
    canUpdate: false,

    computedItems: screen.width < 600 ? 2 : 10,
    computedHeight: screen.width < 600 ? 48 * 12.2 : 48 * 11,

    alertType: "",
    alertMessage: "",
    dialerList: [] as Dialer[],
    trunkList: [] as Trunk[],
    playingSound: [] as HTMLAudioElement[],
    stateIcons: [] as string[],
    selectedItem: null as Dialer | null,
    search: "",
    dialog: false,
    dialogDelete: false,
    loadingList: false,
  }),
  computed: {
    ...mapGetters({
      views: "auth/views",
      hoverColor: "lookAndFeel/hoverColor",
      menuIconColor: "lookAndFeel/menuIconColor",
      menuFontColor: "lookAndFeel/menuFontColor",
    }),
  },
  async mounted() {
    this.loadingList = true;
    this.dialerList = await this.getDialerList(action.DIALER_CONTROLLER);
    this.trunkList = await this.getTrunkList(action.TRUNK_CONTROLLER);

    const arrTrunkIds = [
      ...this.trunkList.map((el) => {
        return { id: el.id, number: el.number };
      }),
    ];

    for (let i = 0; i < this.dialerList.length; i++) {
      for (let j = 0; j < arrTrunkIds.length; j++) {
        if (arrTrunkIds[j].id === this.dialerList[i].trunkId) {
          this.dialerList[i].trunkIdName = arrTrunkIds[j].number;
        }
      }
      this.stateIcons.push("mdi-play");
    }
    this.playingSound.length = this.dialerList.length;

    this.actionHandler();
    this.loadingList = false;
  },

  components: {
    CSV,
    CopyButton,
    Top,
    DialerDetail
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    ...mapActions({
      getDialerList: "crudService/getList",
      getTrunkList: "crudService/getList",
      deleteDialer: "crudService/deleteObject",
    }),
    headers() {
      return [
        {
          text: i18n.t("misc.actions"),
          value: "actions",
          sortable: false,
          align: "right",
        },
        {
          text: i18n.t("misc.status"),
          align: "start",
          value: "status",
        },
        {
          text: i18n.t("misc.name"),
          align: "start",
          value: "name",
        },
        {
          text: i18n.t("misc.trunk"),
          align: "start",
          value: "trunkIdName",
        },
        {
          text: "",
          value: "controls",
          sortable: false,
          align: "right",
        },
      ];
    },
    actionHandler() {
      const view = this.views.find((v: View) => v.name == views.DIALER); //view name
      if (view) {
        this.canDelete = view.actions.includes(crud.DELETE);
        this.canCreate = view.actions.includes(crud.CREATE);
        this.canUpdate = view.actions.includes(crud.UPDATE);
      }
    },

    deleteItem(item: Dialer) {
      this.selectedItem = item;
      this.dialogDelete = true;
    },

    async downloadFile(item: Dialer) {
      const file = item.sound;
      const url = config.url + "discador/getsound/" + file;
      const token = localStorage.getItem("token");

      const response = await fetch(url, {
        credentials: "same-origin",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = item.sound;
      link.click();
      URL.revokeObjectURL(link.href);
    },
    setPlay(item: Dialer) {
      let index = this.dialerList.indexOf(item);
      let icon;
      icon = this.stateIcons[index];

      if (icon === "mdi-play") {
        icon = "mdi-pause";
        this.playSound(item);
      } else {
        icon = "mdi-play";
        this.pauseSound(item);
      }

      this.stateIcons[index] = icon;
    },
    async playSound(val: Dialer) {
      const index = this.dialerList.indexOf(val);
      const file = val.sound;
      const url = config.url + "discador/getsound/" + file;
      const token = localStorage.getItem("token");
      const result = await fetch(url, {
        credentials: "same-origin",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const blob = await result.blob();
      if (blob) {
        const url = URL.createObjectURL(blob);
        this.playingSound[index] = new Audio(url);
        this.playingSound[index].addEventListener("ended", () => {
          this.stateIcons[index] = "mdi-play";
        });
        this.playingSound[index].play();
        this.reloadList();
      } else {
        alert("Cannot load");
      }
    },
    pauseSound(val: Dialer) {
      const index = this.dialerList.indexOf(val);
      this.playingSound[index].pause();
      this.reloadList();
    },
    reloadList() {
      let itemListUpdated = [...this.dialerList];
      this.dialerList = itemListUpdated.filter((row) => row.id);
    },

    async deleteItemConfirm() {
      const response = await this.deleteDialer({
        action: action.DIALER_CONTROLLER,
        id: this.selectedItem?.id,
      });
      this.closeDelete();
      if (response) {
        this.alertType = "green darken-1";
        this.alertMessage = ((i18n.t("misc.deleted") as string) +
          i18n.t("misc.defaultSuccess")) as string;
        let itemListUpdated = [...this.dialerList];
        this.dialerList = itemListUpdated.filter(
          (el) => el.id != this.selectedItem?.id
        );
      } else {
        this.alertType = "error";
        this.alertMessage = i18n.t("misc.defaultError") as string;
      }
      this.$store.dispatch("snackbar/showSnack", {
        message: this.alertMessage,
        color: this.alertType,
        timeout: 3000,
      });
    },

    closeDelete() {
      this.dialogDelete = false;
    },

    async refreshList() {
      this.loadingList = true;
      this.dialerList = await this.getDialerList(action.DIALER_CONTROLLER);
      this.trunkList = await this.getTrunkList(action.TRUNK_CONTROLLER);

      const arrTrunkIds = [
        ...this.trunkList.map((el) => {
          return { id: el.id, number: el.number };
        }),
      ];

      for (let i = 0; i < this.dialerList.length; i++) {
        for (let j = 0; j < arrTrunkIds.length; j++) {
          if (arrTrunkIds[j].id === this.dialerList[i].trunkId) {
            this.dialerList[i].trunkIdName = arrTrunkIds[j].number;
          }
        }
        this.stateIcons.push("mdi-play");
      }
      this.playingSound.length = this.dialerList.length;

      this.actionHandler();
      this.loadingList = false;
    }
  },
});
