

import Vue from "vue";

import Top from "@/components/templates/Top.vue";

import msgCodes from "@/extendedFunctions/msgCodes";
import { mapActions } from "vuex";
import i18n from "@/plugins/i18n";
import * as action from "@/store/actions";
import { Queue, SpeedDialCorp } from "@/types/conf";


export default Vue.extend({
  name: "SpeedDialCorpsDetail",
  props: ["speedDialToUpdate", "isToEdit", "isToNew"],
  data: () => ({
    dialog: false,

    alertType: "",
    alertMessage: "",
    valid: false,

    newSD: {
      number: 0,
      action: null,
    } as SpeedDialCorp,
    actionList: [] as any[],

    exceptionList: [
      i18n.t("lists.---HANG UP---"),
      i18n.t("lists.---IVRS---"),
      i18n.t("lists.---QUEUES---"),
      i18n.t("lists.---INTEGRATIONS---"),
      i18n.t("lists.---EXTENSIONS---"),
    ],
    queueList: [] as Queue[],

    rules: {
      required: (v: any) => !!v || i18n.t("loginView.required"),
      numRule: (v: number) =>
        (v >= 10 && v < 100) || i18n.t("rules.between10and99"),
    },
    isNew: false,
  }),
  computed: {
    comptActions(): { text: string; value?: string; disabled?: boolean }[] {
      let basicActions = [
        {
          text: i18n.t("sdActions.voiceMailMain"),
          value: "VoiceMailMain",
        },
        {
          text: i18n.t("sdActions.meetMe"),
          value: "MeetMe",
        },
        {
          text: i18n.t("sdActions.pickup"),
          value: "Pickup",
        },
        {
          text: i18n.t("sdActions.chanSpy"),
          value: "ChanSpy",
        },
        {
          text: i18n.t("sdActions.whisper"),
          value: "Whisper",
        },
        {
          text: i18n.t("sdActions.reminder"),
          value: "Reminder",
        },
        {
          text: i18n.t("sdActions.forward"),
          value: "Forward",
        },
        // {
        //   text: i18n.t("sdActions.enfileirarSoporte"),
        //   value: "Enfileirar soporte",
        // },
        // {
        //   text: i18n.t("sdActions.loginSoporte"),
        //   value: "Login soporte",
        // },
        // {
        //   text: i18n.t("sdActions.logoutSoporte"),
        //   value: "Logout soporte",
        // },
      ] as { text: string; value?: string; disabled?: boolean }[];

      this.queueList.forEach((queue) => {
        basicActions = [
          ...basicActions,
          {
            text: `${i18n.t("misc.actions")} ${queue.name}:`,
            disabled: true,
          },
          {
            text: `Enfilar ${queue.name}`,
            value: `enqueue_${queue.corpId}_${queue.id}`,
          },
          {
            text: `Login ${queue.name}`,
            value: `login_${queue.corpId}_${queue.id}`,
          },
          {
            text: `Logout ${queue.name}`,
            value: `logout_${queue.corpId}_${queue.id}`,
          },
        ];
      });

      return basicActions;
    },

    // comptActions(): { text: string; value?: string; disabled?: boolean }[] {
    //   return this.actionList.map((item) => {
    //     if(typeof item === "object") return { text: Object.keys(item)[0], value: Object.keys(item)[0], disabled: this.exceptionList.includes(item) };

    //     return {
    //       text: item,
    //       value: item,
    //       disabled: this.exceptionList.includes(item),
    //     };
    //   });
    // },
  },
  components: {   
    Top
  },
  methods: {
    ...mapActions({
      getQueues: "crudService/getList",
      getActions: "crudService/getDetail",
      newSpeedDial: "crudService/newObject",
      updateSpeedDial: "crudService/updateObject",
    }),

    speedDialDetailHandler(speedDial: SpeedDialCorp) {
      this.newSD.number = speedDial.number;
      this.newSD.action = speedDial.action;
    },
    async validate() {

      const actionList = await this.getActions({
        action: action.GET_ACTIONS,
        id: this.$store.state.auth.user.corpId,
      });
      
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {

        let body = {
          id: null as any,
          Number: parseInt(this.newSD.number),
          Action: this.newSD.action,
        };

        if (!!this.newSD.action) {
          if (actionList.queues[this.newSD.action]) body.Action = actionList.queues[this.newSD.action]
          else body.Action = this.newSD.action
        }

        var response;
        if (this.isNew) {
          response = await this.newSpeedDial({
            action: action.SPEED_DIAL_CORP_CONTROLLER,
            obj: body,
          });
                   
        } else {
          body.id = this.speedDialToUpdate.id;
          response = await this.updateSpeedDial({
            action: action.SPEED_DIAL_CORP_CONTROLLER,
            obj: body,
          });
        }

        // This set if the form must persist or don't
        this.dialog = response >= 400;

        this.alertMessage = msgCodes(response, this.isNew).alertMessage;
        this.alertType = msgCodes(response, this.isNew).alertType;

        this.$store.dispatch("snackbar/showSnack", {
          message: this.alertMessage,
          color: this.alertType,
          timeout: 3000,
        });

        this.$emit("refreshList", this.dialog);
      }
    },

    async updateActionList() {

      const actionList = await this.getActions({
        action: action.GET_ACTIONS,
        id: this.$store.state.auth.user.corpId,
      });

      const arr = [];

      arr.push({text: i18n.t("lists.---HANG UP---"), value: "---HANG UP---"});
      arr.push( { text: "Hangup" , value: actionList.hangup }  );

      arr.push({text: i18n.t("lists.---IVRS---"), value: "---IVRS---"});
      for (const key in actionList.ivrs) {      
        arr.push( { text: key , value: actionList.ivrs[key] } );
      }

      arr.push({text: i18n.t("lists.---QUEUES---"), value: "---QUEUES---"});
      for (const key in actionList.queues) {
        arr.push( { text: key , value: actionList.queues[key] } );
      }

      arr.push({text: i18n.t("lists.---INTEGRATIONS---"), value: "---INTEGRATIONS---"});
      for (const key in actionList.integrations) {
        arr.push( { text: key , value: actionList.integrations[key] } );
      }

      arr.push({text: i18n.t("lists.---EXTENSIONS---"), value: "---EXTENSIONS---"});
      for (const key in actionList.extens) {
        arr.push({ text: key , value: actionList.extens[key] } );
      }

      this.actionList = arr;
    }
  },
  watch: {
    '$i18n.locale': {
      handler: function(newLocale, oldLocale) {
        this.updateActionList();
      },
      immediate: true // Trigger the handler immediately on component mount
    }
  },
  async mounted() {
    this.queueList = await this.getQueues(action.QUEUE_CONTROLLER);

    const isNew = this.speedDialToUpdate == null || this.speedDialToUpdate == "undefined";

    this.isNew = isNew;

    if (!isNew) {
      this.speedDialDetailHandler(this.speedDialToUpdate);
    }    
  },
});
