

import Vue from "vue";

import FileSizeInfo from "@/components/templates/FileSizeInfo.vue";

import msgCodes from "@/extendedFunctions/msgCodes";
import { mapActions } from "vuex";
import * as action from "@/store/actions";
import i18n from "@/plugins/i18n";
import { IVR } from "@/types/conf";

export default Vue.extend({
  name: "IVRsDetail",
  props: ["ivrToUpdate", "isToEdit", "isToNew"],
  data: () => ({
    dialog: false,

    alertType: "",
    alertMessage: "",
    valid: false,

    ivr: {
      name: "",
      sound: null,
      soundIncorrect: null,
      option0: null,
      option1: null,
      option2: null,
      option3: null,
      option4: null,
      option5: null,
      option6: null,
      option7: null,
      option8: null,
      option9: null,
    } as IVR,
    actionList: [] as any[],
    fileSound1: "",
    fileSound2: "",
    exceptionList: [
      i18n.t("lists.---HANG UP---"),
      i18n.t("lists.---IVRS---"),
      i18n.t("lists.---QUEUES---"),
      i18n.t("lists.---INTEGRATIONS---"),
      i18n.t("lists.---EXTENSIONS---"),
    ],
    playingSound: [] as any[],
    iconsetting: true,
    rules: {
      required: (v: any) => !!v || i18n.t("loginView.required"),
      numRule: (v: number) =>
        (v >= 10 && v < 100) || i18n.t("rules.between10and99"),
      audioRule: (v: File) =>
        !v || v.size < 2000000 || i18n.t("rules.invalidOption"),
    },
    isNew: false,
  }),
  
  computed: {
    computedActions(): { text: string; value: string; disabled: boolean; }[] {
      return this.actionList.map(item => {
        return {
          text:  item.text,        
          value: item.value,  
          disabled: this.exceptionList.includes(item.text),
        };
      });
    },
  },
  components: {
    FileSizeInfo,
  },

  methods: {
    ...mapActions({
      getActions: "crudService/getDetail",
      newIvr: "crudService/newObject",
      updateIvr: "crudService/updateObject",
      getFile: "fileGetters/getFile",
    }),

    progress(pass: string) {
      return Math.min(100, pass.length * 10);
    },

    nameRules(text: string) {
      return text.match(/^[a-zA-Z0-9_ ]*$/) ? true : i18n.t("rules.alphanum");
    },

    async ivrDetailHandler(ivr: IVR) {

      this.ivr.name = ivr.name;
      this.ivr.sound = ivr.sound;
      this.ivr.soundIncorrect = ivr.soundIncorrect;
      this.ivr.option0 = ivr.option0;
      this.ivr.option1 = ivr.option1;
      this.ivr.option2 = ivr.option2;
      this.ivr.option3 = ivr.option3;
      this.ivr.option4 = ivr.option4;
      this.ivr.option5 = ivr.option5;
      this.ivr.option6 = ivr.option6;
      this.ivr.option7 = ivr.option7;
      this.ivr.option8 = ivr.option8;
      this.ivr.option9 = ivr.option9;
      this.playingSound[0] = !!this.ivrToUpdate.sound
        ? new Audio( URL.createObjectURL(
              new Blob(this.blobMaker(this.fileSound1), {
                type: "audio/x-wav",
              })
            )
          )
        : null;
      this.playingSound[1] = !!this.ivrToUpdate.soundIncorrect
        ? new Audio( URL.createObjectURL(
              new Blob(this.blobMaker(this.fileSound2), {
                type: "audio/x-wav",
              })
            )
          )
        : null;
    },
    
    blobMaker(wavString: string) {
      let len = wavString.length;
      let buf = new ArrayBuffer(len);
      let view = new Uint8Array(buf);
      for (let i = 0; i < len; i++) {
        view[i] = wavString.charCodeAt(i) & 0xff;
      }
      
      return [view];
    },
    
    async validate() {
        // Asegúrate de que el campo 'sound' no esté vacío
      if (!this.ivr.sound) {
        // Maneja el caso donde 'sound' está vacío, por ejemplo, mostrando un mensaje al usuario
        this.$store.dispatch("snackbar/showSnack", {
          message: i18n.t('misc.noAudio'),
          color: "error",
          timeout: 3000,
        });
        this.dialog = true;
      }
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        const actionList = await this.getActions({
          action: action.GET_ACTIONS,
          id: this.$store.state.auth.user.corpId,
        });
        
        let body = new FormData();
        body.append("Name", this.ivr.name);

        body.append("Sound", this.ivr.sound);

        body.append("SoundIncorrect", this.ivr.soundIncorrect);

        if (!!this.ivr.option0) {
          if (actionList.queues[this.ivr.option0]) body.append("option0", actionList.queues[this.ivr.option0])
          else body.append("option0", this.ivr.option0)
        }

        if (!!this.ivr.option1) {
          if (actionList.queues[this.ivr.option1]) body.append("option1", actionList.queues[this.ivr.option1])
          else body.append("option1", this.ivr.option1)
        }

        if (!!this.ivr.option2) {
          if (actionList.queues[this.ivr.option2]) body.append("option2", actionList.queues[this.ivr.option2])
          else body.append("option2", this.ivr.option2)
        }

        if (!!this.ivr.option3) {
          if (actionList.queues[this.ivr.option3]) body.append("option3", actionList.queues[this.ivr.option3])
          else body.append("option3", this.ivr.option3)
        }

        if (!!this.ivr.option4) {
          if (actionList.queues[this.ivr.option4]) body.append("option4", actionList.queues[this.ivr.option4])
          else body.append("option4", this.ivr.option4)
        }

        if (!!this.ivr.option5) {
          if (actionList.queues[this.ivr.option5]) body.append("option5", actionList.queues[this.ivr.option5])
          else body.append("option5", this.ivr.option5)
        }

        if (!!this.ivr.option6) {
          if (actionList.queues[this.ivr.option6]) body.append("option6", actionList.queues[this.ivr.option6])
          else body.append("option6", this.ivr.option6)
        }

        if (!!this.ivr.option7) {
          if (actionList.queues[this.ivr.option7]) body.append("option7", actionList.queues[this.ivr.option7])
          else body.append("option7", this.ivr.option7)
        }

        if (!!this.ivr.option8) {
          if (actionList.queues[this.ivr.option8]) body.append("option8", actionList.queues[this.ivr.option8])
          else body.append("option8", this.ivr.option8)
        }

        if (!!this.ivr.option9) {
          if (actionList.queues[this.ivr.option9]) body.append("option9", actionList.queues[this.ivr.option9])
          else body.append("option9", this.ivr.option9)
        }

        let response;

        if (this.isNew) {
          response = await this.newIvr({
            action: action.IVR_CONTROLLER,
            obj: body,
          });
        } else {
          body.append("id", this.ivrToUpdate.id);
          response = await this.updateIvr({
            action: action.IVR_CONTROLLER,
            obj: body,
          });
        }
        
        // This set if the form must persist or don't
        this.dialog = response >= 400

        if (response <= 400 && this.isNew) {
          this.ivr= {
            name: "",
            sound: null,
            soundIncorrect: null,
            option0: null,
            option1: null,
            option2: null,
            option3: null,
            option4: null,
            option5: null,
            option6: null,
            option7: null,
            option8: null,
            option9: null,
          } as IVR;
        }
        
        this.alertMessage = msgCodes(response, this.isNew).alertMessage;
        this.alertType = msgCodes(response, this.isNew).alertType;

        this.$store.dispatch("snackbar/showSnack", {
          message: this.alertMessage,
          color: this.alertType,
          timeout: 3000,
        });

        this.$emit("refreshList", this.dialog)
      }
    },
    
    resetInput() {
      this.ivr.sound = null;
      this.playingSound[0] = null;
    },
    
    resetInputInc() {
      this.ivr.soundIncorrect = null;
      this.playingSound[1] = null;
    },

    async updateActionList() {
      const actionList = await this.getActions({
        action: action.GET_ACTIONS,
        id: this.$store.state.auth.user.corpId,
      });

      const arr = [];

      arr.push({text: i18n.t("lists.---HANG UP---"), value: "---HANG UP---"});
      arr.push( { text: "Hangup" , value: actionList.hangup }  );

      arr.push({text: i18n.t("lists.---IVRS---"), value: "---IVRS---"});
      for (const key in actionList.ivrs) {      
        arr.push( { text: key , value: actionList.ivrs[key] } );
      }

      arr.push({text: i18n.t("lists.---QUEUES---"), value: "---QUEUES---"});
      for (const key in actionList.queues) {
        arr.push( { text: key , value: actionList.queues[key] } );
      }

      arr.push({text: i18n.t("lists.---INTEGRATIONS---"), value: "---INTEGRATIONS---"});
      for (const key in actionList.integrations) {
        arr.push( { text: key , value: actionList.integrations[key] } );
      }

      arr.push({text: i18n.t("lists.---EXTENSIONS---"), value: "---EXTENSIONS---"});
      for (const key in actionList.extens) {
        arr.push({ text: key , value: actionList.extens[key] } );
      }

      this.actionList = arr;
    }
  },

  watch: {
    '$i18n.locale': {
      handler: function(newLocale, oldLocale) {
        this.updateActionList();
      },
      immediate: true // Trigger the handler immediately on component mount
    }
  },

  async mounted() {
    
    const isNew = this.isToNew;

    this.isNew = isNew;

    if (!isNew) {
      this.fileSound1 = !!this.ivrToUpdate.sound
        ? await this.getFile({
            fileName: this.ivrToUpdate.sound,
            fileType: "sounds",
            endpointName: "ivr",
          })
        : null;
      this.fileSound2 = !!this.ivrToUpdate.soundIncorrect
        ? await this.getFile({
            fileName: this.ivrToUpdate.soundIncorrect,
            fileType: "sounds",
            endpointName: "ivr",
          })
        : null;
        
      await this.ivrDetailHandler(this.ivrToUpdate);
    }
  },

});
