

import Vue from "vue";

import Top from "@/components/templates/Top.vue";
import CSV from "@/components/templates/CSV.vue";
import CopyButton from "@/components/templates/CopyButton.vue";
import ServersDetail from "./ServersDetail.vue";

import { mapActions, mapGetters } from "vuex";

import i18n from "@/plugins/i18n";
import * as views from "@/plugins/utils/View";
import * as crud from "@/plugins/utils/Crud";
import * as action from "@/store/actions";
import { Server, View } from "@/types/conf";

export default Vue.extend({
  name: "",
  data: () => ({
    canDelete: false,
    canCreate: false,
    canUpdate: false,

    computedItems: screen.width < 600 ? 3 : 10,
    computedHeight: screen.width < 600 ? 48 * 12.2 : 48 * 11,

    alertType: "",
    alertMessage: "",
    itemList: [] as Server[],
    selectedItem: null as Server | null,
    search: "",
    dialog: false,
    dialogDelete: false,
    loadingList: false,
  }),
  computed: {
    ...mapGetters({
      views: "auth/views",
      hoverColor: "lookAndFeel/hoverColor",
      menuIconColor: "lookAndFeel/menuIconColor",
      menuFontColor: "lookAndFeel/menuFontColor",
    }),
  },
  async mounted() {
    this.loadingList = true;
    this.itemList = await this.getServerList(action.SERVER_CONTROLLER);
    this.actionHandler();
    this.loadingList = false;
  },

  components: {
    CSV,
    CopyButton,
    Top,
    ServersDetail
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    ...mapActions({
      getServerList: "crudService/getList",
      deleteServer: "crudService/deleteObject",
    }),
    headers() {
      return [      
        {
          text: i18n.t("misc.actions"),
          value: "actions",
          sortable: false,
        },
        { text: i18n.t("misc.name"), value: "name" },
        {
          text: i18n.t("rules.ipOrDomain"),
          align: "start",
          value: "ip",
        },
        {
          text: i18n.t("misc.port"),
          align: "start",
          value: "port",
        }
      ];
    },
    actionHandler() {
      const view = this.views.find((v: View) => v.name == views.SERVERS); //view name
      if (view) {
        this.canDelete = view.actions.includes(crud.DELETE);
        this.canCreate = view.actions.includes(crud.CREATE);
        this.canUpdate = view.actions.includes(crud.UPDATE);
      }
    },

    deleteItem(item: Server) {
      this.selectedItem = item;
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      const response = await this.deleteServer({
        action: action.SERVER_CONTROLLER,
        id: this.selectedItem?.id,
      });
      this.closeDelete();
      if (response) {
        this.alertType = "green darken-1";
        this.alertMessage = ((i18n.t("misc.deleted") as string) +
          i18n.t("misc.defaultSuccess")) as string;
        let itemListUpdated = [...this.itemList];
        this.itemList = itemListUpdated.filter(
          (server) => server.id != this.selectedItem?.id
        );
      } else {
        this.alertType = "error";
        this.alertMessage = i18n.t("misc.defaultError") as string;
      }
      this.$store.dispatch("snackbar/showSnack", {
        message: this.alertMessage,
        color: this.alertType,
        timeout: 3000,
      });
    },

    closeDelete() {
      this.dialogDelete = false;
    },

    async refreshList() {
      this.loadingList = true;
      this.itemList = await this.getServerList(action.SERVER_CONTROLLER);
      this.actionHandler();
      this.loadingList = false;
    },
  },
});
