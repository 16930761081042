
import Vue from "vue";
export default Vue.extend({
  props: ["copyData", "headers"],
  data() {
    return {
      arr: [] as { text: string; value: any }[],
      exceptions: [
        this.$t("misc.actions"),
        "Features",
        "",
        this.$t("misc.audio"),
        this.$t("rules.invalidOption"),
        this.$t("misc.options"),
      ],
    };
  },
  methods: {
    toCSV() {
      if (this.copyData == undefined || this.copyData.length < 1) return;
      const items = this.copyData;
      const replacer = (key: any, value: any) =>
        value === null || value === "" ? "" : value;
      const header = this.arr;
      const csv = [
        header.map((el) => `"${el.text}"`).join(","),
        ...items.map((row: any) =>
          header
            .map((obj) => JSON.stringify(row[obj.value], replacer))
            .join(",")
        ),
      ].join("\r\n");

      return csv;
    },
  },
  mounted() {
    this.arr = this.headers.filter(
      (el: { text: string; value: any }) => !this.exceptions.includes(el.text)
    );
  },
});
